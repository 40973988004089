.custom-apikeys-wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100% - 1px);
    height: calc(100% - 8px);
    padding-top: 60px;

    .custom-apikeys-toolbar-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        top: 0px;
        z-index: 2;
        height: 64px;
        padding: 0px 32px 8px;
        flex-direction: column;

        .toolbar-Breadcrumbs-contain {
            flex-shrink: 1;
            min-width: 0px;
            display: inline-flex;
            flex-flow: row nowrap;
            width: 100%;
            align-items: center;

            a {
                text-decoration: none;
                flex: 0 0 auto;
                display: inline-block;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 160px;
                color: inherit;
                padding: 0.25rem 8px;
                border-radius: 0.3125rem;
                font-family: Inter, sans-serif;
                font-size: 1.125rem;
                line-height: 1.5rem;
                letter-spacing: 0px;
                font-weight: 500;
                margin-left: -0.25rem;
                margin-right: 0.25rem;
                overflow: hidden;

                &:hover {
                    background-color: rgb(238, 238, 240);
                }
            }
        }
    }
}