:root {
  --duration: 1.5s;
  --container-size: 250px;
  --box-size: 33px;
  --box-border-radius: 15%;
}

* {
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0);
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: rgba(255, 255, 255, 0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: rgba(255, 255, 255, 0);
  }
}

.projects-loading {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  background-color: #4a7c7c5c;
  z-index: 10000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.ImageBackgroundOverlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(124, 124, 124, 0.3);
  z-index: 2;
}

.sortby-wrapper {
  display: flex;
  align-self: stretch;
  align-items: center;
  padding: 0px 8px 0px 0px;
  flex-shrink: 0;

  .sortby-title {
    font-family: Inter, sans-serif;
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0px;
    font-weight: 600;
    margin-right: 8px;
  }

  .toolbar-SortBy-select {
    .MuiSelect-select {
      padding-left: 0px !important;

      svg {
        display: none;
      }

      span {
        margin-right: 0.25rem;
        color: rgb(93, 100, 123);
        font-family: Inter, sans-serif;
        font-size: 0.8125rem;
        line-height: 1rem;
        letter-spacing: 0px;
        font-weight: 500;
      }
    }

    fieldset {
      border: none;
    }
  }

  &:hover {
    .sortby-title {
      color: rgb(0, 152, 253);
    }

    .toolbar-SortBy-select {
      .MuiSelect-select {
        span {
          color: rgb(0, 152, 253);
        }
      }

      .MuiSvgIcon-root {
        color: rgb(0, 152, 253);
      }
    }
  }

}

.toolbar-SortBy-select-header {
  font-family: Inter, sans-serif;
  font-size: 0.8125rem;
  line-height: 1rem;
  letter-spacing: 0px;
  font-weight: 600;
  width: 100%;
  padding: 8px 16px;
}

.toolbar-SortBy-select-item {
  background-color: rgb(255, 255, 255);
  border: 0px;
  border-radius: 5px;
  color: rgb(25, 32, 51);
  padding: 8px 16px;
  text-align: left;
  width: 100%;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    opacity: 0.7;
  }

  .radio-outline {
    display: block;
  }

  .radio-checked {
    display: none;
    color: rgb(0, 152, 253);
  }

  span {
    font-family: Inter, sans-serif;
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0px;
    font-weight: 500;
    color: currentcolor;
    cursor: pointer;
    margin-left: 8px;
  }

  &.Mui-selected {
    span {
      color: rgb(25, 32, 51);
    }

    .radio-outline {
      display: none;
    }

    .radio-checked {
      display: block;
    }
  }
}

.setting-select {
  width: 100%;
  height: 30px;

  .MuiSelect-select {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.video-ctrl {
  .MuiSlider-thumb {
    transition: none !important;
  }
}

.fullscreen-wrapper {
  left: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}
.swal2-container{
  z-index: 999999999 !important;
}