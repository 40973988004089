.footer-containner,
.pricing-footer-containner {
	padding: 96px 3%;
	background-image: linear-gradient(180deg, #000428, #000 16%);

	.Footer {
		width: 100%;
		margin-right: auto;
		margin-left: auto;

		.Footer-content {
			display: flex;
			flex-direction: row;
			width: 100%;

			.Footer-Item {
				margin-right: 15px;

				.fHeader {
					color: #fff;
					padding-bottom: 8px;
					padding-left: 8px;
					transition: color .2s;
					font-size: 14px;
					line-height: 20px;
					font-weight: 500;
				}

				.fContent {
					transition-property: none;
					color: hsla(210, 0%, 92%, .7);
					font-family: 'Euclidcirculara webfont', Arial, sans-serif;
					font-size: 13px;
					text-align: left;
					width: 100%;
					border-radius: 6px;
					padding: 8px;
				}

				.FLogo {
					img {
						margin-bottom: 24px;
					}
				}

				.FContent-1 {
					max-width: 360px;
					margin-bottom: 10px;
					font-family: 'Euclidcirculara webfont', Arial, sans-serif;
					color: #fff;
					font-size: 16px;
					line-height: 24px;
					text-align: left;
					margin-top: 16px;
					font-weight: 400;
				}

				.social-link {
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					.social-footer {
						display: flex;
						margin-top: 48px;
						margin-bottom: 16px;
						flex-direction: row;
						justify-content: flex-start;
						align-items: center;

						a {
							display: flex;
							width: 32px;
							height: 32px;
							margin-right: 10px;
						}

						.social-link-1 {
							background-image: url('./../../assets/image/home-footer/Social-Link1.png');
						}

						.social-link-2 {
							background-image: url('./../../assets/image/home-footer/Social-Link2.png');
						}

						.social-link-3 {
							background-image: url('./../../assets/image/home-footer/Social-Link3.png');
						}

						.social-link-4 {
							background-image: url('./../../assets/image/home-footer/Social-Link4.png');
						}
					}

					.text-rights {
						display: block;
						margin-right: 32px;
						padding-top: 0;
						padding-bottom: 0;
						font-family: 'Euclidcirculara webfont', Arial, sans-serif;
						color: #ffffff69;
						font-size: 12px;
						font-weight: 400;
						text-decoration: none;
					}
				}
			}
		}
	}
}

.footer-containner {
	.Footer {
		max-width: 1200px;

		.Footer-content {
			.Footer-Item {
				width: calc((100% - 90px)/6);
			}
		}
	}

	@media screen and (max-width: 991px) {
		padding-top: 96px;

		.Footer {
			.Footer-content {
				flex-direction: column;

				.Footer-Item {
					width: 100%;
					margin-top: 40px;

					&:nth-child(1) {
						margin: unset;
					}
				}
			}
		}
	}
}

.pricing-footer-containner {
	.Footer {
		.Footer-content {
			.Footer-Item {
				width: calc((100% - 90px)/7);

				&:first-child {
					width: calc((100% - 90px)/3.5);
					padding-right: 6.25rem;
				}

				.fHeader {
					font-family: Euclid, sans-serif;
					font-size: 20px;
					line-height: 125%;
					font-weight: 600;
					padding: 8px;
				}

				.fContent {
					font-family: Euclid, sans-serif;
					font-size: 14px;
					font-weight: 400;
					text-decoration: none;
					color: rgba(235, 235, 235, 0.7);
					padding: 8px;
				}

				.FContent-1 {
					font-family: Euclid;
					font-size: 1rem;
					line-height: 150%;
					color: rgb(255, 255, 255);
					margin-bottom: 2.5rem;
					max-width: 360px;
				}
			}
		}
	}

	@media screen and (max-width: 1025px) {
		padding-top: 96px;

		.Footer {
			.Footer-content {
				flex-direction: column;

				.Footer-Item {
					width: 100% !important;
					margin-top: 40px;

					&:nth-child(1) {
						margin: unset;
					}
				}
			}
		}
	}
}

a {
	cursor: pointer;
}