.custom-profile-wrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% - 1px);
  height: calc(100% - 8px);

  .custom-profile-toolbar-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    z-index: 2;
    height: 64px;
    padding: 0px 32px 8px;

    .toolbar-Breadcrumbs-contain {
      flex-shrink: 1;
      min-width: 0px;
      display: inline-flex;
      flex-flow: row nowrap;
      width: 100%;
      align-items: center;

      a {
        text-decoration: none;
        flex: 0 0 auto;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 160px;
        color: inherit;
        padding: 0.25rem 8px;
        border-radius: 0.3125rem;
        font-family: Inter, sans-serif;
        font-size: 1.125rem;
        line-height: 1.5rem;
        letter-spacing: 0px;
        font-weight: 500;
        margin-left: -0.25rem;
        margin-right: 0.25rem;
        overflow: hidden;

        &:hover {
          background-color: rgb(238, 238, 240);
        }
      }
    }
  }
  .profile-textfield {
    padding: 6px;
    border: solid 1px #000;
    font-size: 1.5rem;
    border-radius: 4px;
    text-align: center;
    width: 100%;

    &:disabled {
      opacity: 0.8;
        // background-color: #fff;
    }
  }

  .profile-delete-account {
    background-color: #d32f2f;
    padding: 10px;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
  }
}