.plan-interval-section {
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin: 2rem 0rem;

  .plan-interval-contain {
    position: relative;
    height: 3rem;
    width: 14.5rem;
    background-color: rgb(238, 238, 240);
    border-radius: 999px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 0.25rem;
    margin-bottom: 1rem;
    gap: 1rem;

    .plan-iterval-toggle,
    .plan-iterval-toggle-active {
      cursor: pointer;

      .plan-iterval-text {
        font-family: Euclid, sans-serif;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .plan-iterval-toggle {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border-radius: 999px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;

      .plan-iterval-text {
        color: rgb(160, 164, 178);
        font-weight: 400;
      }
    }

    .plan-iterval-toggle-active {
      width: 100%;
      height: 100%;
      background-color: rgb(255, 255, 255);
      border-radius: 999px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: rgb(0 0 0 / 3%) 0px 116.667px 93.3333px, rgb(0 0 0 / 2%) 0px 48.7406px 38.9924px, rgb(0 0 0 / 2%) 0px 26.059px 20.8472px, rgb(0 0 0 / 2%) 0px 14.6085px 11.6868px, rgb(0 0 0 / 1%) 0px 7.75845px 6.20676px, rgb(0 0 0 / 1%) 0px 3.22847px 2.58277px;

      .plan-iterval-text {
        color: rgb(25, 32, 51);
        font-weight: 600;
      }
    }

    .annually-SaveUpTo-contain {
      position: absolute;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      flex-direction: row-reverse;
      gap: 0.25rem;
      bottom: -80%;
      left: 5%;

      .saveupto-text {
        font-family: Euclid, sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        padding-top: 0.75rem
      }

      .saveupto-img {
        display: none;
      }

    }
  }

  @media (min-width: 768px) {
    .plan-interval-contain {
      height: 3.5rem;
      margin-bottom: 0rem;

      .annually-SaveUpTo-contain {
        flex-direction: row;
        inset: -15% -100% auto auto;

        .saveupto-img {
          display: block;
        }

        .mobile-saveupto-img {
          display: none;
        }
      }
    }
  }
}

.freePlan-brder{
  border: 2px solid #fb0000ad !important;
}

.plan-hightlight-section {

  .plan-hightlight-contain {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
    padding: 0px 1rem;

    .plan-hightlight-items {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      justify-content: center;

      .plan-hightlight-item {
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        padding: 1.5rem;
        width: 19rem;
        background-color: rgb(0 0 0 / 16%);

        .plan-hightlight-header {
          width: 100%;
          display: flex;
          gap: 1rem;
          margin-bottom: 0.5rem;
          -webkit-box-align: center;
          align-items: center;

          .plan-hightlight-headerimg {
            height: 2.75rem;
            width: 2.75rem;
          }

          .plan-hightlight-headertxt {
            font-family: Euclid, sans-serif;
            font-weight: 400;
            font-size: 32px;
            margin: 0px;
            padding: 0px;
          }
        }

        .plan-hightlight-description {
          font-family: Euclid, sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          // color: rgb(83, 89, 110);
          margin: 0px;
        }

        .plan-hightlight-price {
          display: flex;
          line-height: 1;
          margin-top: 1rem;

          .plan-hightlight-pricetxt {
            font-family: Euclid, sans-serif;
            font-size: 56px;
            font-weight: 700;
            margin: 0px;
            padding-right: 0.25rem;
          }
        }

        .plan-hightlight-price-description {
          font-family: Euclid, sans-serif;
          font-size: 16px;
          font-weight: 400;
          // color: rgb(64, 69, 88);
          margin-bottom: 1rem;
          height: 1.3rem;
        }

        .plan-hightlight-gobtn {
          text-decoration: none;
          background: rgb(0, 152, 253);
          border: 1px solid rgb(0, 152, 253);
          transition: border 0.2s ease-in-out 0s;
          color: rgb(255, 255, 255);
          border-radius: 10px;
          height: 48px;
          padding: 0px 16px;
          display: flex;
          min-width: 87px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          user-select: none;
          letter-spacing: 0px;

          font-family: Euclid, sans-serif;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 1rem;
          box-shadow: rgb(0 0 0 / 10%) 0px 1px 1px 0px;
          width: 100%;
        }

        .disaled {
          background-color: #bdbdc1;
          border-color: #bdbdc1;
          cursor: not-allowed;
        }

        .plan-hightlight-featurelist {
          display: flex;
          flex-direction: column;
          place-content: center left;
          gap: 0.5rem;
          margin-bottom: 1rem;
          padding: 0px;

          .plan-hightlight-featurelist-item {
            display: flex;
            align-items: center;
            gap: 0.75rem;

            .plan-hightlight-featurelist-txt {
              font-family: Euclid, sans-serif;
              font-size: 16px;
              font-weight: 600;
              padding: 0px;
              margin: 0px;
            }
          }
        }

        .plan-hightlight-footer {
          font-family: Euclid, sans-serif;
          font-size: 16px;
          font-weight: 400;
          // color: rgb(25, 32, 51);
          padding: 0px;
          margin: auto 0px 0.5rem;
          text-decoration: underline;
        }
      }

      .active-plan-container {
        border-width: 3px;
        border-style: solid;
        padding: calc(1.5rem - 3px) 1.5rem 1.5rem;
      }

      .active-plan-txt {
        position: absolute;
        top: -0.85rem;
        right: 1.5rem;

        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        font-family: Euclid, sans-serif;
        color: rgb(255, 255, 255);

        margin: 0px;
        border-radius: 20px;
        padding: 0.5rem 1rem;
      }

      .most-popular {
        @extend .active-plan-container;
        border-color: rgb(255, 213, 130);
        // background-color: rgb(255, 235, 196);

        .most-popular-txt {
          @extend .active-plan-txt;
          background-color: rgb(255, 180, 69);
        }
      }

      .custom-plan {
        @extend .active-plan-container;
        border-color: #fb0000ad;

        .custom-text {
          @extend .active-plan-txt;
          background-color: #fb0000;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .plan-hightlight-contain {
      min-height: 34.5rem;
    }
  }
}

.contactEnterprise-section {
  .contactEnterprise-contain {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 4rem;
    padding: 0px 1rem;

    .contactEnterprise-txt {
      font-family: Euclid, sans-serif;
      font-size: 20px;
      font-weight: 600;
      line-height: 25px;
      text-align: center;
      margin: 0px;
    }

    .contactEnterprise-btn {
      background: rgb(238, 238, 240);
      border: 1px solid rgb(238, 238, 240);
      transition: border 0.2s ease-in-out 0s;
      color: rgb(25, 32, 51);
      border-radius: 10px;
      height: 48px;
      padding: 0px 16px;
      display: flex;
      min-width: 87px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;
      line-height: 1.4;
      letter-spacing: 0px;

      font-family: Euclid, sans-serif;
      font-size: 16px;
      text-align: center;
      font-weight: 600;
      width: 14rem;
    }
  }
}

.usedByLogos-section {
  .usedByLogos-contain {
    margin: 4rem auto;
    padding: 0px 1rem;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: flex-start;

    .usedByLogos-item {
      svg {
        color: rgb(160, 164, 178);
      }
    }
  }

  @media (min-width: 768px) {
    .usedByLogos-contain {
      gap: 4rem;
    }
  }
}

.compare-plans-section {
  .compare-plans-contain {
    // background-color: rgb(247, 247, 248);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 1rem;

    .compare-header {
      font-family: Euclid, sans-serif;
      font-size: 36px;
      font-weight: 600;
      text-align: center;
    }

    .compare-description {
      font-family: Euclid, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      max-width: 28rem;
      margin: 1rem 0px;
    }

    .compares-grid {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(4, 1fr);
      place-items: center;
      -webkit-box-align: center;
      gap: 0.5rem;
      max-width: 77rem;

      .compares-grid-header-contain {
        z-index: 2;
        display: grid;
        width: 100%;
        grid-column: 1 / -1;
        grid-template-columns: repeat(4, 1fr);
        place-items: center;
        gap: 0.5rem;
        max-width: 77rem;
        padding-top: 1rem;
        position: sticky;
        top: 0px;
        // background: linear-gradient(rgb(247, 247, 248) calc(100% - 1rem), rgba(247, 247, 248, 0) 100%);

        .compares-grid-headerTitle {
          place-self: center;
          visibility: hidden;
          display: none;
          font-family: Euclid, sans-serif;
          font-size: 18px;
          font-weight: 600;
          margin: 0px;
        }

        .compares-grid-header-item {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          -webkit-box-align: center;
          align-items: center;
          width: 100%;

          .compares-GH-content-contain {
            display: flex;
            flex-direction: column;

            .compares-GH-content-img {
              height: 2rem;
              width: 2rem;
              margin-bottom: 1rem;
              box-shadow: rgb(25 32 51 / 25%) 0px 10px 15px;
              border-radius: 50%;
            }

            .compares-GH-content {
              font-family: Euclid, sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;
              margin: 0px;
              padding: 0px;

              .compares-GH-content_T1 {
                font-family: Euclid, sans-serif;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                margin: 0px 0px 0.25rem;
                padding: 0px;
              }

              .compares-GH-content_T2 {
                font-family: Euclid, sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                margin: 0px;
                padding: 0px;
              }
            }
          }

          .compares-GH-btn {
            text-decoration: none;
            width: 100%;
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(223, 224, 229);
            transition: border 0.2s ease-in-out 0s;
            color: rgb(25, 32, 51);
            box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px;
            border-radius: 10px;
            height: 48px;
            padding: 0px 16px;
            min-width: 87px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;
            line-height: 1.4;
            letter-spacing: 0px;
            display: none;
            font-family: Euclid, sans-serif;
            font-size: 12px;
            font-weight: 500;
            max-width: 9.375rem;
            margin: 0px;
            padding: 0px;
          }

          .disaled {
            cursor: not-allowed;
          }
        }

        .compares-grid-header-bottom {
          border: 1px solid #8d8f95;
          width: 100%;
          grid-column: 1 / -1;
        }
      }

      .compares-grid-list-header {
        font-family: Euclid, sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: rgb(130, 136, 154);
        margin: 0px;
        grid-column: 1 / -1;
        justify-self: left;
      }

      .compares-gridList-featureTitle-contain {
        width: 100%;
        grid-column: 1 / -1;
        justify-self: left;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        gap: 0.5rem;
        margin: 1.5rem 0rem 0.5rem;

        .compares-gridList-featureTitle {
          font-family: Euclid, sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          margin: 0px;
          // color: rgb(25, 32, 51);
        }

        .compares-gridList-featureTitle-Icon {
          display: none;
        }
      }

      .compares-gridlist-icon {
        stroke-width: 1.5px;
        overflow: visible;
      }

      .compares-gridList-itemtitle {
        font-family: Euclid, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        overflow-wrap: break-word;
        hyphens: auto;
        margin: 0px;
      }

      .compares-gridList-link {
        font-family: Euclid, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: rgb(0, 152, 253);
        margin: 0px;
        text-decoration: none;
      }

      .compares-gridList-border {
        border: 1px solid #9fa0a7;
        width: 100%;
        grid-column: 1 / -1;
        z-index: 1;
      }
    }
  }

  @media (min-width: 768px) {
    .compare-plans-contain {
      .compare-header {
        font-size: 48px;
      }

      .compares-grid {
        grid-template-columns: repeat(5, 1fr);
        gap: 1.5rem 1rem;

        .compares-grid-header-contain {
          grid-template-columns: repeat(5, 1fr);
          gap: 1.5rem 1rem;

          .compares-grid-headerTitle {
            visibility: visible;
            display: block;
          }

          .compares-grid-header-item {
            .compares-GH-content-contain {
              flex-direction: row;
              gap: 1rem;
              -webkit-box-align: center;
              align-items: center;

              .compares-GH-content-img {
                margin: 0px;

                .compares-GH-content_T1 {
                  margin: 0px;
                }
              }

              .compares-GH-content {}
            }

            .compares-GH-btn {
              visibility: visible;
              display: flex;
            }
          }
        }

        .compares-gridList-featureTitle-contain {
          grid-column: 1 / auto;
          margin: 1rem 0rem;

          .compares-gridList-featureTitle-Icon {
            display: flex;
          }
        }
      }
    }
  }
}

.frequently-question-section {
  max-width: 77rem;
  margin: 4rem auto;

  .frequently-question-contain {
    display: grid;
    grid-template-columns: 1fr;
    -webkit-box-pack: center;
    place-content: center;
    place-items: center;

    .frequently-question-header {
      font-family: Euclid, sans-serif;
      font-size: 38px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 0px;
      text-align: center;
      margin: 1rem 0px 2rem;
      padding: 0rem 2rem;
    }

    .frequently-question-items {
      width: 100%;
      display: flex;
      flex-direction: column;
      -webkit-box-pack: center;
      justify-content: center;
      padding: 0px 1rem;
      margin-top: -0.5rem;

      .frequently-question-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex: 1 1 0%;

        .frequently-question-item-header {
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;
          cursor: pointer;

          .frequently-question-item-headerTitle {
            font-family: Euclid, sans-serif;
            font-size: 16px;
            font-weight: 400;
            // color: rgb(83, 89, 110);
            margin: 0px;
          }

          .frequently-question-upIcon {
            transform: rotate(180deg);
            transition: all 0.33s ease 0s;
            flex-shrink: 0;
          }

          .frequently-question-downIcon {
            transition: all 0.33s ease 0s;
            flex-shrink: 0;
          }
        }

        .frequently-question-content {
          font-family: Euclid, sans-serif;
          font-size: 16px;
          text-align: justify;
          hyphens: auto;
          padding-right: 2rem;

          .frequently-question-contentTitle {
            margin: 0px;
          }

          .frequently-question-contentLink {
            background-color: initial;
            color: #007bff;
            text-decoration: none;
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .frequently-question-contain {
      grid-template-columns: 40% 60%;
      align-items: flex-start;

      .frequently-question-items {
        padding: 0px 2rem;
      }
    }
  }

  @media (min-width: 768px) {
    .frequently-question-contain {
      .frequently-question-header {
        padding: 0rem 3rem;
        margin-top: 0px;
        text-align: left;
      }
    }
  }
}


.planModal-section {
  .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    background-color: rgba(25, 32, 51, 0.2);
  }

  .planModal-box-contain {
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    justify-content: center;
    justify-items: center;
    align-items: center;
    box-sizing: border-box;
    margin: 0px;

    &:focus-visible {
      outline: none;
    }

    .planMoal-box {
      box-shadow: rgb(0 0 0 / 15%) 0px 2px 16px;
      overflow: hidden;
      transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s, transform 0.15s ease-in-out 0s;
      cursor: default;
      background: rgb(247, 247, 248);
      position: relative;
      width: 100%;
      height: 100%;

      .planModal-closeBtn {
        border: 0px;
        width: 24px;
        height: 24px;
        background: rgb(247, 247, 248);
        color: rgb(93, 100, 123);
        border-radius: 100%;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
      }

      .planModal-container {
        height: 100%;
        padding: 0.5rem;
        overflow: auto;
        border-radius: 15px;

        .planModal-containerGrid {
          display: grid;
          grid-template-columns: 100%;
          height: 100%;
          gap: 1rem;

          .planModal-containerGrid-left {
            display: flex;
            flex-direction: column;
            background: rgb(255, 255, 255);
            width: 100%;
            height: 100%;
            border-radius: 15px;
            box-shadow: rgb(0 0 0 / 10%) 0px 0px 2px, rgb(0 0 0 / 3%) 0px 100px 80px, rgb(0 0 0 / 2%) 0px 41.7776px 33.4221px, rgb(0 0 0 / 2%) 0px 22.3363px 17.869px, rgb(0 0 0 / 2%) 0px 12.5216px 10.0172px, rgb(0 0 0 / 1%) 0px 6.6501px 5.32008px, rgb(0 0 0 / 1%) 0px 2.76726px 2.21381px;

            .planModal-GridLeft-backbtn {
              margin: 1rem 0px 0px 1rem;

              .planModal-GridLeft-Btn {
                padding: 0px;
                border: 0px;
                background: none;
                margin-right: 0.5rem;
                border-radius: 0.5rem;
                width: 2rem;
                height: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &:hover {
                  background: rgb(238, 238, 240);
                }

                svg {
                  color: black;
                  font-size: large;
                }
              }
            }

            .planModal-GridLeft-hader {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              margin: 1rem 1rem 2rem;

              img {
                width: 60px;
                height: 60px;
                margin-right: 1rem;
              }

              .planModal-GridLeft-haderTitle {
                font-family: Inter, sans-serif;
                font-size: 1.125rem;
                line-height: 1.4;
                letter-spacing: 0px;
                font-weight: 600;
                color: rgb(25, 32, 51);
                margin-bottom: 0.1rem;
              }

              .planModal-GridLeft-haderContent {
                font-family: Inter, sans-serif;
                font-size: 0.8125rem;
                letter-spacing: 0px;
                font-weight: 400;
                color: rgb(93, 100, 123);
                line-height: 1.125rem;
              }
            }

            .planModal-GridLeft-body {
              margin: 0px 1rem;

              .planModal-GridLeft-items-contain {
                margin: 0px 0px 2rem 0.5rem;

                .planModal-GridLeft-bodyitems {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  height: 100%;

                  .planModal-GridLeft-bodyitem {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 1rem;
                    font-size: 0.8125rem;
                    line-height: 1.4;
                    letter-spacing: 0px;
                    color: rgb(83, 89, 110);
                    font-weight: 500;
                    text-align: left;

                    .planModal-GridLeft-bodyitemIcon {
                      width: 16px;
                      height: 16px;
                      background: rgb(255, 255, 255);
                      border-radius: 100%;
                      display: flex;
                      -webkit-box-pack: center;
                      justify-content: center;
                      -webkit-box-align: center;
                      align-items: center;
                      box-shadow: rgb(0 0 0 / 5%) 0px 5px 10px;
                      margin-right: 0.5rem;
                    }
                  }
                }
              }
            }

            .planModal-GridLeft-footer {
              border-top: 2px solid rgb(238, 238, 240);
              display: flex;
              -webkit-box-pack: justify;
              justify-content: space-between;
              -webkit-box-align: center;
              align-items: center;
              padding: 1rem 0px;
              margin: auto 1rem 0px;

              .planModal-GridLeftFooter-helperbtn {
                font-family: Inter, sans-serif;
                font-size: 0.8125rem;
                letter-spacing: 0px;
                font-weight: 500;
                color: rgb(25, 32, 51);
                cursor: pointer;
                display: flex;
                align-items: center;
                line-height: 0;
                background: none;
                border: 0px;
                padding: 0px;

                svg {
                  stroke-width: 1.5px;
                  overflow: visible;
                  margin-right: 0.25rem;
                }
              }

              .planModal-GridLeftFooter-link {
                font-family: Inter, sans-serif;
                font-size: 0.8125rem;
                line-height: 1.4;
                letter-spacing: 0px;
                font-weight: 500;
                color: rgb(25, 32, 51);
                cursor: pointer;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                text-decoration: none;

                &:hover {
                  color: #0056b3;
                  text-decoration: underline;
                }

                svg {
                  margin-left: 0.25rem;
                }
              }
            }
          }

          .planModal-containerGrid-right {
            display: flex;
            flex-direction: column;
            -webkit-box-pack: justify;
            justify-content: space-between;
            margin: 1rem 0.5rem 0.5rem;

            .planModal-GridRight-title {
              font-family: Inter, sans-serif;
              font-size: 1.125rem;
              line-height: 1.4;
              letter-spacing: 0px;
              font-weight: 500;
              margin-bottom: 1rem;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
            }

            .planModal-GridRight-contain {
              position: relative;
              height: 100%;
              display: flex;
              flex-direction: column;
              -webkit-box-pack: justify;
              justify-content: space-between;

              .planModal-GridRight-planIntervaltitle {
                font-family: Inter, sans-serif;
                font-size: 0.8125rem;
                line-height: 1.4;
                letter-spacing: 0px;
                font-weight: 500;
              }

              .planModal-GridRight-planIntervalGrid {
                display: grid;
                gap: 1rem;
                grid-template-columns: 100%;
                margin-bottom: 2rem;

                .planModal-GridRight-planInterval-Item,
                .planModal-GridRight-planInterval-ItemActive {
                  position: relative;
                  box-sizing: border-box;
                  border-radius: 0.9375rem;
                  display: flex;
                  align-items: center;
                  opacity: 1;
                  padding: 0.75rem;
                  justify-content: flex-start;

                  .planModal-planInterval-group {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.25rem;

                    .planModal-planInterval-groupCircle {
                      width: 1rem;
                      height: 1rem;
                      border-radius: 100%;
                      margin-right: 0.5rem;
                    }

                    .planModal-planInterval-groupTitle {
                      font-family: Inter, sans-serif;
                      font-size: 0.8125rem;
                      line-height: 1.4;
                      letter-spacing: 0px;
                      font-weight: 600;

                      span {
                        font-weight: 400;
                      }
                    }
                  }

                  .planModal-planInterval-calculation {
                    display: flex;
                    align-items: center;
                    font-family: Inter, sans-serif;
                    font-size: 0.8125rem;
                    line-height: 1.4;
                    letter-spacing: 0px;
                    font-weight: 500;
                    color: rgb(93, 100, 123);

                    .planModal-calculation-baseprice {
                      margin-right: 0.2rem;
                      font-family: Inter, sans-serif;
                      font-size: 1.125rem;
                      line-height: 1.4;
                      letter-spacing: 0px;
                      font-weight: 500;
                      color: rgb(25, 32, 51);
                    }
                  }
                }

                .planModal-GridRight-planInterval-ItemActive {
                  background: rgb(239, 249, 255);
                  border: 1.5px solid rgb(103, 193, 255);
                  color: rgb(0, 152, 253);

                  .planModal-planInterval-group {
                    .planModal-planInterval-groupCircle {
                      border: 4px solid rgb(0, 152, 253);
                    }
                  }
                }

                .planModal-GridRight-planInterval-Item {
                  border: 1.5px solid rgb(223, 224, 229);
                  color: rgb(25, 32, 51);

                  &:hover {
                    background: rgb(239, 249, 255);
                    border: 1.5px solid rgb(103, 193, 255);
                    color: rgb(0, 152, 253);
                  }

                  .planModal-planInterval-group {
                    .planModal-planInterval-groupCircle {
                      border: 2px solid rgb(144, 148, 165);
                    }
                  }
                }
              }

              .planModal-GridRight-body {
                margin-bottom: 2rem;

                .planModal-rightBody-inputgrid {
                  display: grid;
                  column-gap: 1rem;
                  grid-template-columns: 1fr;

                  .planModal-rightBody-inputItem {
                    font-family: Inter, sans-serif;
                    font-size: 0.8125rem;
                    line-height: 1.4;
                    letter-spacing: 0px;
                    font-weight: 400;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                  }

                  input {
                    border-radius: 0.5rem;
                    font-size: inherit;
                    border: 1px solid rgb(223, 224, 229);
                    order: 2;
                    color: rgb(93, 100, 123);
                    opacity: 1;
                    background: none;
                    padding: 0.875rem;

                    &:hover {
                      border-color: rgb(103, 193, 255);
                    }

                    &:focus-visible {
                      outline: none;
                    }

                    &:focus {
                      border-color: rgb(103, 193, 255);
                    }

                    &:focus+label {
                      color: rgb(0, 152, 253);
                    }
                  }

                  label {
                    opacity: 1;
                    font-family: Inter, sans-serif;
                    font-size: 0.8125rem;
                    line-height: 1.4;
                    letter-spacing: 0px;
                    font-weight: 500;
                    color: rgb(25, 32, 51);
                    margin-bottom: 8px;
                    width: 100%;
                    display: inline-block;
                    order: 1;
                  }
                }

                .planModal-rightBody-cardElementWrapper {
                  border: 1px solid rgb(223, 224, 229);
                  border-radius: 0.5rem;
                  padding: 1rem;

                  &:hover {
                    border-color: rgb(103, 193, 255);
                  }

                  .planModal-rightBody-cardElement {
                    margin: 0px !important;
                    padding: 0px !important;
                    border: none !important;
                    display: block !important;
                    background: transparent !important;
                    position: relative !important;
                    opacity: 1 !important;

                    iframe {
                      border: none !important;
                      margin: 0px !important;
                      padding: 0px !important;
                      width: 1px !important;
                      min-width: 100% !important;
                      overflow: hidden !important;
                      display: block !important;
                      user-select: none !important;
                      transform: translate(0px) !important;
                      color-scheme: light only !important;
                      height: 14.4px;
                    }
                  }
                }
              }

              .planModal-GridRight-footer {
                display: flex;
                flex-direction: column;
                -webkit-box-pack: end;
                justify-content: flex-end;

                .planModal-rightFooter-ErrTxt {
                  height: 35px;

                  .planModal-ErrText {
                    text-align: center;
                    font-family: Inter;
                    font-size: 0.7rem;
                    color: rgb(255, 84, 84);
                    margin-bottom: 2rem;
                  }
                }

                .planModal-rightFooter-group {
                  display: grid;
                  grid-template-columns: 1fr;
                  gap: 1rem;
                  margin-bottom: 2rem;

                  .planModal-rightFooter-submitBtn {
                    width: 100%;
                    border: 1px solid rgb(0, 152, 253);
                    transition: border 0.2s ease-in-out 0s;
                    color: rgb(255, 255, 255);
                    border-radius: 10px;
                    height: 48px;
                    padding: 0px 16px;
                    min-width: 87px;
                    align-items: center;
                    cursor: pointer;
                    user-select: none;
                    font-family: Inter, sans-serif;
                    font-size: 0.8125rem;
                    line-height: 1.4;
                    letter-spacing: 0px;
                    font-weight: 500;
                    display: flex;
                    justify-content: space-between;
                    background: rgb(0, 152, 253);

                    .planModal-submitBtn-price {
                      font-family: Inter, sans-serif;
                      font-size: 0.9375rem;
                      line-height: 1.4;
                      letter-spacing: 0px;
                      font-weight: 600;
                    }
                  }

                  .planModal-CancelBtn {
                    width: 100%;
                    background: rgb(238, 238, 240);
                    border: 1px solid rgb(238, 238, 240);
                    transition: border 0.2s ease-in-out 0s;
                    color: rgb(25, 32, 51);
                    border-radius: 10px;
                    height: 48px;
                    padding: 0px 16px;
                    display: flex;
                    min-width: 87px;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    user-select: none;
                    font-family: Inter, sans-serif;
                    font-size: 0.8125rem;
                    line-height: 1.4;
                    letter-spacing: 0px;
                    font-weight: 500;
                  }
                }

                .planModal-rightFooter-footerTxt {
                  font-family: Inter, sans-serif;
                  font-size: 0.8125rem;
                  line-height: 1.4;
                  letter-spacing: 0px;
                  font-weight: 400;
                  color: rgb(93, 100, 123);

                  a {
                    color: #007bff;
                    text-decoration: none;
                  }
                }

                .rightFooter-subscription-Error {
                  width: 100%;
                  display: flex;
                  -webkit-box-pack: justify;
                  justify-content: space-between;
                  background: rgba(255, 84, 84, 0.1);
                  border: 1.5px solid rgba(255, 84, 84, 0.3);
                  box-sizing: border-box;
                  border-radius: 1.25rem;
                  padding: 0.75rem;

                  .Subscription-left {
                    display: flex;
                    flex-direction: column;
                    padding: 0.75rem;
                    -webkit-box-pack: center;
                    justify-content: center;

                    .Subscription-left-header {
                      font-family: Inter, sans-serif;
                      line-height: 1.4;
                      letter-spacing: 0px;
                      font-weight: 600;
                      font-size: 12px;
                    }

                    .Subscription-left-content {
                      font-family: Inter, sans-serif;
                      line-height: 1.4;
                      letter-spacing: 0px;
                      font-weight: 500;
                      color: rgb(93, 100, 123);
                      font-size: 12px;
                    }

                    .Subscription-left-footer {
                      margin-top: 16px;
                      font-family: Inter, sans-serif;
                      line-height: 1.4;
                      letter-spacing: 0px;
                      font-weight: 500;
                      color: rgb(93, 100, 123);
                      font-size: 12px;

                      .Subscription-sendAgain {
                        font-family: Inter, sans-serif;
                        line-height: 1.4;
                        letter-spacing: 0px;
                        font-weight: 600;
                        font-size: 12px;
                        color: rgb(0, 152, 253);
                        margin-left: 5px;
                      }
                    }
                  }

                  .Subscription-right {
                    display: flex;
                    box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px;
                    border-radius: 0.625rem;
                    background: white;

                    .Subscription-EmailIcon-Err {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                      border: 1px solid rgb(223, 224, 229);
                      text-decoration: none;
                      font-family: Inter, sans-serif;
                      font-size: 0.8125rem;
                      line-height: 1.4;
                      letter-spacing: 0px;
                      font-weight: 400;
                      color: rgb(93, 100, 123);
                      flex-grow: 1;
                      border-bottom-left-radius: 0.625rem;
                      border-top-left-radius: 0.625rem;

                      svg {
                        stroke-width: 1.5px;
                        overflow: visible;
                      }
                    }
                  }
                }
              }
            }

            .subscription-disable {
              opacity: 0.3;
            }
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    .planModal-GridRight-body {
      @apply absolute w-full h-full overflow-y-auto overflow-x-hidden;
    }
  }

  @media (min-width: 1024px) {
    .planModal-box-contain {
      .planMoal-box {
        width: 90%;
        max-width: 90vw;
        max-height: 90vh;
        border-radius: 20px;

        .planModal-container {
          .planModal-containerGrid {
            .planModal-containerGrid-right {}
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    .planModal-box-contain {
      .planMoal-box {
        .planModal-container {
          .planModal-containerGrid {
            grid-template-columns: 42% 1fr;

            .planModal-containerGrid-left {
              .planModal-GridLeft-hader {
                justify-content: center;
                margin: 2rem 2rem 2rem 3rem;

                img {
                  width: 80px;
                  height: 80px;
                  margin-right: 2rem;
                }
              }
            }

            .planModal-containerGrid-right {
              .planModal-GridRight-title {
                margin-bottom: 2rem;
              }

              .planModal-GridRight-contain {
                .planModal-GridRight-footer {
                  .rightFooter-subscription-Error {
                    height: 8rem;

                    .Subscription-left {}

                    .Subscription-right {}
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .planModal-box-contain {
      .planMoal-box {
        .planModal-container {
          .planModal-containerGrid {
            .planModal-containerGrid-left {
              .planModal-GridLeft-backbtn {
                margin: 0.5rem 0px 0px 0.5rem;
              }

              .planModal-GridLeft-hader {
                margin: 2rem 3rem;
              }

              .planModal-GridLeft-body {
                margin: 0px 2rem 0px 3rem;
              }

              .planModal-GridLeft-footer {
                margin: auto 3.5rem 1rem;
              }
            }

            .planModal-containerGrid-right {
              margin: 1.5rem;

              .planModal-GridRight-contain {
                .planModal-GridRight-planIntervalGrid {
                  grid-template-columns: 1fr 1fr;
                  margin-bottom: 1rem;

                  .planModal-GridRight-planInterval-Item,
                  .planModal-GridRight-planInterval-ItemActive {
                    justify-content: center;
                    min-height: 6rem;
                    padding: 0px;
                  }
                }

                .planModal-GridRight-body {
                  margin-bottom: 1rem;
                }

                .planModal-GridRight-footer {
                  .planModal-rightFooter-group {
                    grid-template-columns: 1fr 30%;
                  }

                  .planModal-rightFooter-ErrTxt {
                    .planModal-ErrText {
                      margin-bottom: 1rem;
                    }
                  }

                  .rightFooter-subscription-Error {
                    .Subscription-right {
                      .Subscription-EmailIcon-Err {
                        padding: 40px 32px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.pricing-modal {
  border-radius: 1.25rem;
}