p{
    margin-top: 16px;
    margin-bottom: 32px;
    font-family: "Euclidcirculara webfont", Arial, sans-serif;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 0.5px;

    ul{
        list-style-type: decimal;
        padding-left: 35px;
    }
}

