.custom-projects-wrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% - 1px);
  height: calc(100% - 8px);
  padding-top: 60px;

  .custom-projects-toolbar-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    z-index: 2;
    height: 64px;
    padding: 0px 32px 8px;
    flex-direction: column;

    .toolbar-Breadcrumbs-contain {
      flex-shrink: 1;
      min-width: 0px;
      display: inline-flex;
      flex-flow: row nowrap;
      width: 100%;
      align-items: center;

      a {
        text-decoration: none;
        flex: 0 0 auto;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 160px;
        color: inherit;
        padding: 0.25rem 8px;
        border-radius: 0.3125rem;
        font-family: Inter, sans-serif;
        font-size: 1.125rem;
        line-height: 1.5rem;
        letter-spacing: 0px;
        font-weight: 500;
        margin-left: -0.25rem;
        margin-right: 0.25rem;
        overflow: hidden;

        &:hover {
          background-color: rgb(238, 238, 240);
        }
      }
    }

    .toolbar-layoutOption-section {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      .projects-toolbar-style-contain {
        border-left: 1px solid rgb(239, 240, 242);
        padding: 0px 8px;
        flex-shrink: 0;
        cursor: pointer;

        &:hover {
          color: rgb(0, 152, 253);
        }

        svg {
          opacity: 0.6;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .projects-next-preview-btn {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    padding-top: 20px;
  }

  .custom-projects-contents-section {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    .projects-container {
      padding: 16px;

      .projects-item {
        width: 100%;

        .projectTitle-ThumbnailWrapper {
          width: 100%;
          aspect-ratio: 16 / 9;
          margin-bottom: 8px;
          border-radius: 5px;
          position: relative;
          display: grid;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          cursor: pointer;
          text-decoration: none;

          transition: box-shadow 0.2s ease-in-out 0s;

          &:hover {
            color: #0a58ca;
          }

          .projectTitle-ThumbnailBackgroundsContainer {
            position: absolute;
            inset: 0px;
            display: grid;
            align-items: center;
            justify-content: center;

            .projectTitle-ThumbnailImage-section {
              max-width: 100%;
              max-height: 100%;
              z-index: 3;
              grid-area: 1 / 1 / 1 / 1;
              overflow: hidden;
              justify-self: center;
            }

            .projectTitle-ThumbnailImageBackgroundOverlay {
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              background: rgba(124, 124, 124, 0.3);
              z-index: 2;
            }

            .projectTitle-ThumbnailImageBackground {
              width: 110%;
              position: absolute;
              left: -0.5rem;
              filter: blur(50px);
              z-index: 0;
            }
          }

          .projectTitle-ThumbnailActions-section {
            grid-area: 1 / 1 / 1 / 1;
            z-index: 4;
            justify-self: center;
            display: flex;
            gap: 2px;
            opacity: 0;
            transition: opacity 0.2s ease-in-out 0s;

            .ThumbnailAction__ThumbnailActionWrapper {
              border: 0px;
              margin: 0px;
              padding: 8px;
              border-radius: 7px;
              background: rgba(51, 51, 51, 0.6);
              color: rgb(255, 255, 255);
              font-family: Inter, sans-serif;
              font-size: 0.8125rem;
              letter-spacing: 0px;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: center;
              text-decoration: none;
              gap: 4px;
              line-height: 16px;

              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }

        .projectsTitle-TileInfoWrapper {
          display: grid;
          min-height: 40px;
          grid-template:
            "name buttons" min-content
            "time buttons" 1fr / 1fr min-content;

          .TileInfoWrapper-header {
            grid-area: name / name / name / name;
            font-family: Inter, sans-serif;
            font-size: 0.8125rem;
            line-height: 1rem;
            letter-spacing: 0px;
            font-weight: 500;
            // color: rgb(25, 32, 51);
            margin-bottom: 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .TileInfoWrapper-TileInfoTime {
            grid-area: time / time / time / time;
            font-family: Inter, sans-serif;
            font-size: 0.71875rem;
            line-height: 0.75rem;
            letter-spacing: 0.01em;
            font-weight: 400;
            color: rgb(160, 164, 178);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .TileInfoWrapper-TileInfoActions {
            grid-area: buttons / buttons / buttons / buttons;
            display: flex;

            .TileInfoActions-btn {
              background: transparent;
              appearance: none;
              outline: none;
              border: none;
              padding: 0px;
              user-select: none;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              transition: opacity 0.2s ease-in-out 0s;
              width: 40px;
              opacity: 1;
              cursor: pointer;

              &:hover {
                background: rgb(238, 238, 240);
              }

              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }

        &:hover {
          .projectTitle-ThumbnailWrapper {
            .projectTitle-ThumbnailActions-section {
              opacity: 1;
              pointer-events: auto;
              text-decoration: none;
            }
          }
        }
      }
    }

    .list-projects-container {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .projects-header-wrapper {
        display: none;

        .projects-header-NameTableCell1,
        .projects-header-NameTableCell2,
        .projects-header-NameTableCell3,
        .projects-header-NameTableCell4 {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          min-width: 0px;
        }

        .projects-header-NameTableCell1 {
          flex-grow: 1;
          flex-shrink: 2;
        }

        .projects-header-NameTableCell2 {
          flex: 0 0 100px;
        }

        .projects-header-NameTableCell3 {
          flex: 0 0 160px;
        }

        .projects-header-NameTableCell4 {
          flex: 0 0 48px;
        }
      }

      .project-list-item {
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        padding: 8px;
        position: relative;
        .date-modified {
          max-width: 134px;
          text-align: center;
        }
        .download-link {
          display: flex;
          align-items: center;
          gap: 4px;
          .download-icon {
           rotate: -90deg;
           max-width: 16px;
          }
          transition: all 0.2s ease-in-out;
          &:hover{
            opacity: 0.6;
          }
        }
        .listItem-thumbnail {
          object-fit: contain;
        }
        .listItem-cell1-header.title-wrapper {
          position: relative;
          max-width: 55ch;
        }
        .clone-title{
          //texwhite-space: nowrap;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; 
          line-clamp: 2; 
          -webkit-box-orient: vertical;
        }
        .source-target-languages{
          display: flex;
          flex-direction: column;
          align-items: center;
          svg {
            margin-block: -4px;
          }
        }
        .project-listItem-cell4.options {
          cursor: pointer;
          transition: all 0.2s;
          &:hover {
            opacity: 0.6;
          }
        }
        .project-listItem-cell1,
        .project-listItem-cell2,
        .project-listItem-cell3,
        .project-listItem-cell4 {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          min-width: 0px;
          svg {
            width: 24px;
            transform: rotate(90deg) !important;
          }

          img {
            width: 3rem;
            height: 3rem;
            border-radius: 10px;
            flex-shrink: 0;
            margin-right: 0.6875rem;
            background-size: cover;
          }

          .listItem-cell1-text {
            .listItem-cell1-header {
              font-family: Inter, sans-serif;
              font-size: 0.8125rem;
              line-height: 1rem;
              letter-spacing: 0px;
              font-weight: 500;
              color: rgb(25, 32, 51);
              width: 100%;
              overflow: hidden;
              white-space: pre-wrap;
              overflow-wrap: anywhere;
              text-overflow: ellipsis;
            }
          }
          .listItem-cell1-text.listen-audio-mobile-btn {
            display: none;
          }

          .listItem-cell2-text {
            display: flex;
            align-items: center;
            margin-inline: auto;
            font-family: Inter, sans-serif;
            font-size: 0.600rem;
            line-height: 1rem;
            letter-spacing: 0px;
            font-weight: 500;
            color: rgb(93, 100, 123);
            white-space: pre-wrap;
            overflow-wrap: anywhere;
            overflow: hidden;
            text-overflow: ellipsis;

            &::before {
              content: "";
              display: inline-block;
              width: 8px;
              margin-right: 0.5rem;
              height: 8px;
              border-radius: 10rem;
              background-color: rgb(255, 116, 52);
              opacity: 0.8;
            }
          }

          .listItem-cell3-text {
            font-family: Inter, sans-serif;
            font-size: 0.8125rem;
            line-height: 1rem;
            letter-spacing: 0px;
            font-weight: 500;
            color: rgb(93, 100, 123);
            overflow: hidden;
            white-space: pre-wrap;
            overflow-wrap: anywhere;
            text-overflow: ellipsis;
          }

          svg {
            color: rgb(144, 148, 165);
            height: auto;
            width: 16px;
            transform: unset;
          }
        }

        .project-listItem-cell1 {
          flex-grow: 1;
          flex-shrink: 0;
          text-decoration: none;
          .react-loading {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(-90deg);
            margin-right: 11px;
          }
        }

        .project-listItem-cell3 {
          flex: 0 0 160px;
        }

        .project-listItem-cell4 {
          flex: 0 0 48px;
        }
        &:hover {
          // opacity: 0.7;
          transform: translateX(4px);
        }
      }
    }
  }

.no-data-container {
  .audio-waves {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    height: 100%;
  }

  #wave {
    height: 200px;
    width: 200px;
  }

  @for $i from 1 through 9 {
    #Line_#{$i} {
      animation: pulse 1s infinite;
      animation-delay: $i * .15s;
    }
  }

  @keyframes pulse {
    0% {
      transform: scaleY(1);
      transform-origin: 50% 50%;
    }
    
    50% {
      transform: scaleY(.95);
      transform-origin: 50% 50%;
    }
    
    100% {
      transform: scaleY(1);
      transform-origin: 50% 50%;
    }
  }
}

//Media Queries
@media (max-width: 1600px) {
  .projects-header-NameTableCell3.date-modified-heading {
    max-width: 100px;
  }
  .project-list-item {
    .listItem-cell1-header.title-wrapper {
      max-width: 40ch !important;
    }
    .date-modified {
      max-width: 80px !important;
    }
  }
}
@media (max-width: 1440px) {
  .projects-header-NameTableCell3.date-modified-heading {
    max-width: 106px;
  }
  .projects-header-NameTableCell4 {
    display: none !important;
  }
  .project-list-item{
    padding-right: 0 !important;
    .listItem-cell1-header.title-wrapper {
      max-width: 40ch !important;
    }
    .project-listItem-cell4 {
      max-width: max-content;
    }
      
  }
  .project-list-item .project-listItem-cell2.listen-audio-btn {
    display: none !important;
  }
  .listItem-cell1-text.listen-audio-mobile-btn {
    display: flex !important;
  }
  .project-listItem-cell2.download-buttons {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    .downloading-progress-video {
    position: absolute !important;
    z-index: -1;
    top: 3px;
    left: 0;
    }
    .downloading-progress-audio{
    position: absolute !important;
    z-index: -1;
    bottom: 4px;
    left: 0;
    }
  }
}

@media (max-width: 1280px) {
  .project-list-item {
    .listItem-cell1-header.title-wrapper {
     max-width: 40ch !important;
    }
  .project-listItem-cell2.languages-cell {
    max-width: 60px;
  }
  .project-listItem-cell2.progress-cell {
    max-width: 50px;
    progress {
      width: 90% !important;
    }
  }
  .project-listItem-cell2.clone-state {
    max-width: 80px;
    .listItem-cell2-text {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  }
  .no-data-container {  
    .audio-waves {
      height: 70%;
    }
    #wave {
      height: 150px;
      width: 150px;
    }
    .no-voice-warning {
      font-size: 20px;
    }
    .no-voice-desc {
      font-size: 14px;
    }
  }
}
@media (max-width: 1140px) {
  .project-list-item {
    .listItem-cell1-header.title-wrapper {
     max-width: 20ch !important;
    }
  }
}
@media (max-width: 1024px) {
  .projects-header-NameTableCell3.date-modified-heading {
    display: none !important;
  }
  .project-list-item{
    .date-modified {
      display: none !important;
    }      
    .project-listItem-cell2.languages-cell,
    .project-listItem-cell2.progress-cell,
    .project-listItem-cell2.clone-state
     {
  margin-right: 4px !important;
    }
  }
  .no-data-container {     
    #wave {
      height: 100px;
      width: 100px;
    }
    .no-voice-warning {
      font-size: 20px;
    }
    .no-voice-desc {
      max-width: 50ch;
      text-align: center;
      margin-inline: auto;
    }
  }
}
@media (max-width: 900px) {
  .project-listItem-cell2.progress-cell {
    max-width: 30px !important;
    .listItem-cell2-text {
      &::before {
        display: none !important;
      }
    }
    progress {
      width: 90% !important;
    }
  }
  .project-listItem-cell2.languages-cell {
    max-width: 50px !important;
    .listItem-cell2-text {
      &::before {
        display: none !important;
      }

    }
    
  }
  .project-listItem-cell2.download-buttons {
    font-size: 12px;
    max-width: 90px;
  }
  .project-list-item {
    .listItem-cell1-header.title-wrapper {
     max-width: 15ch !important;
     font-size: 12px !important;
    }
  }
}

  @media (min-width: 768px) {
    padding-top: 50px;

    .custom-projects-toolbar-section {
      // background: rgb(247, 247, 248);
      border-bottom: 1px solid rgb(238, 238, 240);
      z-index: 10;
      flex-direction: row;
    }

    .custom-projects-contents-section {
      .list-projects-container {
        .projects-header-wrapper {
          font-family: Inter, sans-serif;
          font-size: 0.8125rem;
          line-height: 1rem;
          letter-spacing: 0px;
          font-weight: 500;
          color: rgb(25, 32, 51);
          padding: 8px 24px 8px 16px;
          border-bottom: 1px solid rgb(238, 238, 240);
          display: flex;
          .projects-header-NameTableCell1,
          .projects-header-NameTableCell2,
          .projects-header-NameTableCell3 {
            margin-right: 1rem;
          }
        }

        .project-list-item {
          padding: 8px 24px 8px 16px;
          //border-bottom: 1.5px solid rgb(238, 238, 240);
          cursor: unset;
          transition: all 0.2s ease-in-out;
          .project-listItem-cell1,
          .project-listItem-cell2,
          .project-listItem-cell3,
          .project-listItem-cell4 {
            margin-right: 1rem;

            .listItem-cell1-text {
              .listItem-cell1-body {
                display: none;
              }
            }

           
          }
         
         
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .custom-projects-contents-section {
      .projects-container {
        padding: 1.875rem 32px 32px;
      }
    }
  }

  @media (max-width: 768px) {
  
    .custom-projects-toolbar-section {
      position: static;
     .toolbar-Breadcrumbs-contain {
      display: flex !important;
      justify-content: center !important;
     }
    }
        .upload-file-btn {
          margin: 0 auto;
          display: block;
          span {
            display: inline !important;
          }
        }
      
    
    
      .project-list-item {
        .listItem-cell1-header.title-wrapper {
        max-width: 40ch !important;
      }
      &:hover {
        // opacity: 0.7;
        transform: none !important;
      } 
      border-bottom-width: 1px;
    }   
    .no-data-container {
      overflow: hidden;
      padding-block: 50px;
      .audio-waves {
        height: 50%;
      }
    }
 }


  @media (max-width: 640px) {
      .project-list-item {
        .listItem-cell1-header.title-wrapper {
        max-width: 20ch !important;
      }
    }
 }
  @media (max-width: 500px) {
    .projects-next-preview-btn {
      button {
        margin: 0;
        padding: 6px;
        border: none;
        font-size: 12px;
        svg {
          width: 16px;
        }
      }
      h4{
        font-size: 16px;
        margin: 0;
      }
    }
      .project-list-item {
  
        padding-block: 14px !important;
        flex-wrap: wrap;
        justify-content: space-between;
        .listItem-cell1-text.title-cell {
          width: 100vw !important;
          .title-wrapper {
            max-width: 34ch !important;
          }
        }
        .project-listItem-cell1 {
          margin-bottom: 10px;
        }
        .project-listItem-cell2.download-buttons,
        .project-listItem-cell2.languages-cell,
        .project-listItem-cell2.progress-cell,
        .project-listItem-cell2.clone-state {
          max-height: 40px;
        }
        .project-listItem-cell4.options {
          margin-right: 10px
        }
    }
    .no-data-container {    
      overflow: hidden;
      padding-block: 20px 100px;
      #wave {
        height: 50px;
        width: 50px;
      }
      .no-voice-desc {
        max-width: 30ch;
      }
    }
 }
}
