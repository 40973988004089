.custom-templates-header-wrapper {
  font-family: Inter, sans-serif;
  font-size: 0.8125rem;
  line-height: 1rem;
  letter-spacing: 0px;
  font-weight: 400;
  border-bottom: 2px solid rgb(223, 224, 229);
  border-top: 2px solid rgb(223, 224, 229);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  padding: 0px 32px;

  .templates-header-items {
    border: 0px;
    display: flex;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;

    .templates-header-item {
      text-decoration: none;
      cursor: pointer;
      background-color: transparent;
      border-width: 0px 0px 2px;
      border-style: initial;
      border-color: initial;
      border-image: initial;
      color: rgb(93, 100, 123);
      margin-right: 8px;
      padding: 16px 8px;
      transform: translateY(2px);

      &:hover {
        color: rgb(25, 32, 51);
        border-radius: 5px;
        background-color: #f5efef;
      }
    }

    .active {
      border-bottom-style: solid;
      color: rgb(25, 32, 51);
      border-radius: 5px;
      border-bottom-color: rgb(0, 152, 253);

      &:hover {
        background-color: #f7f7f8;
      }
    }
  }

  .templates-header-aspectRatioBtn {
    display: flex;
    margin-left: auto;
    position: relative;

    .templates-aspectRatioBtn-select {
      .MuiSelect-select {
        svg {
          width: 16px;
          height: 16px;
          opacity: 0.7;
        }

        span {
          margin-right: 8px;
          margin-left: 8px;
          vertical-align: middle;
          font-size: 13px;
        }
      }

      fieldset {
        border: none;
      }
    }
  }
}