.w-dashboard-contain {
  height: 100%;
  overflow-y: auto;
  position: fixed;
  width: 100%;

  .w-dashboard {
    max-height: 100%;
    height: 100%;
    // background-color: rgb(247, 247, 248);
    padding: 0rem;
    display: flex;
    flex-direction: column;

    .dashboard-sidebar-contain {
      display: flex;
      flex-direction: column;
      position: relative;

      .d-sidebar-section {
        display: flex;
        flex-direction: column;
        padding: 24px 16px 0px;
        overflow-y: inherit;
        border: none;

        .d-logoImg {
          flex-shrink: 0;
          align-self: flex-start;
          width: 100%;
          height: 1.375rem;
          color: rgb(25, 32, 51);
          margin-bottom: 1.4375rem;
        }

        .d-sidebar-plan-menu-contain {
          position: relative;
          display: block;

          .d-sidebar-plan-menu {
            display: flex;
            justify-content: right;
            align-items: center;
            cursor: pointer;

            .dw-plan-logo-section {
              position: relative;

              .dw-plan-logo {
                width: 2rem;
                height: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgb(182, 110, 255);
                border-radius: 0.5rem;
                flex-shrink: 0;
                margin-bottom: 0px;

                span {
                  font-size: 1.1875rem;
                  color: rgb(255, 255, 255);
                  text-shadow: rgb(0 0 0 / 10%) 0px 4px 2px;
                  text-transform: uppercase;
                  font-weight: 700;
                  line-height: 1.75rem;
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  text-align: right;
                }
              }
            }

            .dw-plan-content {
              display: none;
              flex-direction: column;
              width: 100%;
              margin-left: 0.875rem;
              overflow: hidden;
              justify-content: center;

              .dw-plan-content-header {
                display: block;
                font-family: Inter, sans-serif;
                font-size: 0.8125rem;
                letter-spacing: 0px;
                font-weight: 500;
                line-height: 0.9375rem;
              }

              .dw-plan-content-title {
                display: block;
                font-family: Inter, sans-serif;
                font-size: 0.9375rem;
                letter-spacing: 0px;
                font-weight: 500;
                color: rgb(25, 32, 51);
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.1875rem;
                margin-top: 0.0625rem;
                white-space: nowrap;
              }
            }

            .dw-plan-dropdown-icon {
              display: none;
              stroke-width: 2.25px;
              overflow: visible;
              flex-shrink: 0;
              margin-left: 1rem;
              margin-right: 8px;
              color: rgb(83, 89, 110);
            }
          }
        }

        .d-sidebar-newVideoBtn-contian {
          margin-top: 24px;
          margin-bottom: 0.75rem;

          .d-sidebar-newVideoBtn {
            cursor: pointer;
            background: rgb(0, 152, 253);
            transition: border 0.2s ease-in-out 0s;
            color: rgb(255, 255, 255);
            border-radius: 10px;
            height: 48px;
            padding: 0px 16px;
            display: flex;
            min-width: 87px;
            justify-content: space-between;
            align-items: center;
            user-select: none;
            font-family: Inter, sans-serif;
            font-size: 0.8125rem;
            line-height: 1rem;
            letter-spacing: 0px;
            font-weight: 600;
            border: 1.5px solid rgb(0, 141, 235);
            box-shadow: rgb(0 66 111 / 20%) 0px 1px 1px;

            svg {
              margin-left: 16px;
              opacity: 0.5;
              width: 16px;
            }
          }
        }

        .d-sidebar-menu-items {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;

          .d-sidebar-menu-item {
            text-decoration: none;
            margin-bottom: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            height: 30px;
            width: 100%;
            font-family: Inter, sans-serif;
            font-size: 0.9375rem;
            line-height: 1.25rem;
            letter-spacing: 0px;
            font-weight: 500;
            border-radius: 0.5rem;
            padding: 0px 16px;
            user-select: none;
            position: relative;
            cursor: pointer;
            // color: rgb(25, 32, 51);

            &:hover {
              text-decoration: none;
              color: rgb(25, 32, 51);
              background-color: rgb(247, 247, 248);
            }

            .d-sidebar-menu-item-icon {
              svg {
                margin-right: 0.75rem;
                width: 20px;
                height: 20px;
              }
            }

            .d-sidebar-menu-badge-icon {
              margin-left: auto;
            }

            .brand-kit-badge {
              border-radius: 30%;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 24px;
              height: 24px;
              background-color: rgb(255, 163, 26);
            }
          }

          .active {
            text-decoration: none;
            color: rgb(25, 32, 51);
            background-color: rgb(238, 238, 240);

            &:hover {
              background-color: rgb(238, 238, 240);
            }
          }
        }
      }
    }

    .dashboard-content-contain {
      flex: 1 1 0%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .d-cotent-header-section {
        display: none;
        width: 100%;
        height: 64px;
        align-items: center;
        position: sticky;
        top: 0px;
        z-index: 11;
        // background: rgb(247, 247, 248);
        padding: 0px 2rem 0px 1rem;
        gap: 1rem;
        box-shadow: 0px -6px 2px 7px;
        .dC-header-search-wrapper {
          display: flex;
          flex-grow: 1;
          align-items: flex-start;
          position: relative;
          max-width: 26.5rem;
          z-index: 22;
          flex-direction: column;
          padding: 0.75rem 0px 0.8125rem 1rem;
          cursor: pointer;

          &:hover {
            background-color: rgb(238, 238, 240);
            color: rgb(93, 100, 123);
            border-radius: 0.625rem;
          }

          .header-search-btn {
            color: rgb(93, 100, 123);
            margin-bottom: 0px;
            margin-right: 24px;
            padding-left: 0px;

            span {
              font-family: Inter, sans-serif;
              font-size: 0.8125rem;
              line-height: 1rem;
              letter-spacing: 0px;
              font-weight: 500;
              margin-left: 0.625rem;
            }
          }
        }

        .dC-header-ActionContainer {
          flex: 0 0 auto;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.8rem;

          .ActionContainer-upgradeBtn {
            cursor: pointer;
            border: none;
            padding: 0.75rem 1rem;
            border-radius: 0.4375rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgb(25, 32, 51);
            background-color: rgb(255, 241, 221);

            svg {
              width: 16px;
              height: 16px;

              path {
                fill: rgb(255, 163, 26);
              }
            }

            span {
              font-family: Inter, sans-serif;
              font-size: 0.8125rem;
              letter-spacing: 0px;
              font-weight: 500;
              margin-right: 0.375rem;
              line-height: 1rem;
            }

            &:hover {
              background-color: rgb(255, 218, 163);
            }
          }

          .ActionContainer-inviteBtn {
            cursor: pointer;
            height: 2.5rem;
            border-radius: 0.4375rem;
            background: rgb(238, 238, 240);
            border: 1px solid rgb(238, 238, 240);
            transition: border 0.2s ease-in-out 0s;
            color: rgb(25, 32, 51);
            padding: 0px 16px;
            display: flex;
            min-width: 50px;
            justify-content: space-between;
            align-items: center;
            user-select: none;
            font-family: Inter, sans-serif;
            font-size: 0.8125rem;
            line-height: 1rem;
            letter-spacing: 0px;
            font-weight: 500;

            &:hover {
              color: rgb(25, 32, 51);
              background: rgb(223, 224, 229);
              border: 1px solid rgb(223, 224, 229);
              box-shadow: none;
            }

            svg {
              margin-left: 16px;
              opacity: 1;
              stroke-width: 2.25px;
              overflow: visible;
              width: 16px;
              height: 16px;
            }
          }

          .ActionContainer-questionBtn {
            width: 2.5rem;
            height: 2.5rem;
            position: relative;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.5rem;
            color: rgb(25, 32, 51);

            &:hover {
              background-color: rgb(238, 238, 240);
              color: rgb(25, 32, 51);
            }

            svg {
              stroke-width: 2px;
              width: 24px;
              height: 24px;
            }
          }

          .ActionContainer-notificationBtn {
            width: 2.5rem;
            height: 2.5rem;
            position: relative;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.5rem;
            color: rgb(25, 32, 51);

            &:hover {
              background-color: rgb(238, 238, 240);
              color: rgb(25, 32, 51);
            }

            svg {
              width: 24px;
              height: 24px;
            }
          }

          .ActionContainer-userAvata-contain {
            display: flex;
            flex-direction: row;

            .ActionContainer-user-Avata {
              width: 40px;
              height: 40px;
              object-fit: cover;
              border-radius: 100%;
              background-color: rgb(182, 110, 255);
              text-shadow: rgb(0 0 0 / 10%) 0px 4px 2px;
              line-height: 1.75rem;
              font-weight: 700;
              color: white;

              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              font-size: 20px;
              cursor: pointer;

              &:hover {
                background-color: rgb(136, 64, 209);
              }
            }
          }
        }
      }

      .d-content-body-section {
        flex: 1 1 0%;
      }
    }
  }

  @media (min-width: 768px) {
    .w-dashboard {
      display: grid;
      grid-template-columns: 16rem auto;

      .dashboard-sidebar-contain {
        border-color: rgba(238, 238, 240, 0.498);
        border-style: solid;
        border-width: 0px 1px;
        box-shadow: rgb(29 29 31 / 5%) 0px 4px 5px;
        // background: rgb(255, 255, 255);

        .d-sidebar-section {
          overflow-y: auto;
          height: 100%;
          padding-bottom: 16px;

          .d-sidebar-plan-menu-contain {
            display: inline-block;

            .d-sidebar-plan-menu {
              justify-content: center;
              padding: 0.25rem;
              margin: -0.25rem;

              &:hover {
                color: rgb(25, 32, 51);
                text-decoration: none;
                background-color: rgb(247, 247, 248);
                border-radius: 0.5rem;
              }

              .dw-plan-logo-section {
                .dw-plan-logo {
                  width: 3.5rem;
                  height: 3.5rem;
                }
              }

              .dw-plan-content {
                display: flex;
                width: auto;
                flex-grow: 1;
              }

              .dw-plan-dropdown-icon {
                display: flex;
              }
            }
          }
        }
      }

      .dashboard-content-contain {
        .d-cotent-header-section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;

          .dC-header-search-wrapper {}

          .dC-header-ActionContainer {
            margin-left: auto;
            width: auto;
          }
        }
      }

    }
  }

  @media (max-width: 767px) {
    .w-dashboard {
      .dashboard-sidebar-contain {
        .d-sidebar-section {
          padding: 16px;
        }
      }

      .dashboard-content-contain {
        overflow-y: unset;
      }
    }
  }
}


.sidebar-plan-menu-contain {
  .MuiPaper-root {
    left: 5px !important;
  }

  .plan-menu-section {
    border-radius: 0.9375rem;
    display: flex;
    flex-direction: column;
    overflow: visible;
    background-color: rgb(255, 255, 255);
    pointer-events: initial;
    width: 246px;

    .plan-menu-header-sec {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0.25rem;
      margin: 0.5rem 0.5rem 0px;

      &:hover {
        color: rgb(25, 32, 51);
        text-decoration: none;
        background-color: rgb(247, 247, 248);
        border-radius: 0.5rem;
      }

      .pmh-logo-section {
        position: relative;

        .pmh-logo {
          width: 3.5rem;
          height: 3.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgb(182, 110, 255);
          border-radius: 0.5rem;
          flex-shrink: 0;
          margin-bottom: 0px;

          span {
            font-size: 1.1875rem;
            color: rgb(255, 255, 255);
            text-shadow: rgb(0 0 0 / 10%) 0px 4px 2px;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 1.75rem;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            text-align: right;
          }
        }

      }

      .pmh-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 0.875rem;
        overflow: hidden;
        flex-grow: 1;

        .pmh-content-header {
          display: block;
          font-family: Inter, sans-serif;
          font-size: 0.8125rem;
          letter-spacing: 0px;
          font-weight: 500;
          line-height: 0.9375rem;
        }

        .pmh-content-title {
          display: block;
          font-family: Inter, sans-serif;
          font-size: 0.9375rem;
          letter-spacing: 0px;
          font-weight: 500;
          color: rgb(25, 32, 51);
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.1875rem;
          margin-top: 0.0625rem;
          white-space: nowrap;
        }
      }

      .pmh-dropdown-icon {
        display: flex;
        stroke-width: 2.25px;
        overflow: visible;
        flex-shrink: 0;
        margin-left: 1rem;
        margin-right: 8px;
        color: rgb(83, 89, 110);
      }
    }

    .plan-menu-body-sec {
      padding: 0.5rem;

      .plan-menu-subtitle-time-contain {
        background: rgb(247, 247, 248);
        border-radius: 10px;
        padding: 0.75rem;
        margin: 0px 0.25rem 8px;

        .pm-subtitle-time-header {
          font-family: Inter, sans-serif;
          font-size: 0.8125rem;
          line-height: 1rem;
          letter-spacing: 0px;
          font-weight: 500;
          color: rgb(160, 164, 178);
          margin: 0px 0px 10px;
          display: flex;
          align-items: center;

          svg {
            height: 16px;
            width: 16px;
            margin-right: 6px;
          }
        }

        .pm-subtitle-time-title {
          font-family: Inter, sans-serif;
          font-size: 0.8125rem;
          line-height: 1rem;
          letter-spacing: 0px;
          font-weight: 500;
          color: rgb(83, 89, 110);
          margin: 0px;
        }
      }

      .plan-menu-list-contain {
        .plan-menu-list {
          font-family: Inter, sans-serif;
          font-size: 0.8125rem;
          line-height: 1rem;
          letter-spacing: 0px;
          font-weight: 500;
          color: rgb(25, 32, 51);
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          margin-top: 0.5rem;
          padding: 8px;
          margin-left: 4px;
          margin-right: 4px;
          border-radius: 5px;

          svg {
            flex-shrink: 0;
            color: rgb(25, 32, 51);
            margin-right: 12px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .plan-menu-footer-sec {
      padding: 0.5rem;

      .plan-menu-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 0.5rem;

        .plan-menu-footer-title {
          font-family: Inter, sans-serif;
          font-size: 0.8125rem;
          line-height: 1rem;
          letter-spacing: 0px;
          font-weight: 500;
          color: rgb(160, 164, 178);
        }

        .plan-menu-footer-newWorkspaces-btn {
          cursor: pointer;
          color: rgb(83, 89, 110);
          background: none;
          border: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          padding: 8px;
          font-family: Inter, sans-serif;
          font-size: 0.8125rem;
          line-height: 1rem;
          letter-spacing: 0px;
          font-weight: 500;

          svg {
            path {
              fill: #797b81;
            }
          }
        }
      }
    }
  }


  @media (max-width: 768px) {
    .MuiPaper-root {
      left: 8px !important;
      right: 8px !important;
      top: 8px !important;
      max-width: calc(100% - 16px);
      max-height: calc(100% - 16px);
    }

    .plan-menu-section {
      width: 100%;

      .plan-menu-header-sec {
        padding: 1rem;
        margin: 0px;
      }
    }
  }
}

.sidebar-newVideo-menu-contain {
  .MuiList-root {
    padding-bottom: 0px !important;
  }

  .newVideo-menu-section {
    display: flex;
    flex-direction: column;
    overflow: visible;
    pointer-events: initial;
    width: 220px;

    .newVideo-menu-items {

      .newVideo-menu-item {
        margin: 0.25rem 0.5rem;
        margin-top: 0.5rem;

        all: unset;
        width: calc(100% - 16px);
        box-sizing: border-box;
        cursor: pointer;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: Inter, sans-serif;
        font-size: 0.8125rem;
        line-height: 1rem;
        letter-spacing: 0px;
        font-weight: 500;
        color: rgb(83, 89, 110);
        background-color: rgb(255, 255, 255);
        padding: 8px;
        border-radius: 8px;

        .new-video-menu-item-icon {
          border-radius: 50%;
          width: 1.25rem;
          height: 1.25rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .create-icon {
          color: rgb(157, 69, 255);
        }

        .record-icon {
          color: rgb(255, 100, 106);
        }

        .live-icon {
          color: rgb(0, 152, 253);
        }

        span {
          margin-left: 16px;
        }
      }
    }

    .newVM-explore-template {
      cursor: pointer;
      all: unset;
      width: 100%;
      box-sizing: border-box;
      margin-top: 0.5rem;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: Inter, sans-serif;
      font-size: 0.8125rem;
      line-height: 1rem;
      letter-spacing: 0px;
      font-weight: 500;
      color: rgb(93, 100, 123);
      background-color: rgb(255, 255, 255);
      padding: 13px 16px;
      border-top: 1.5px solid rgb(238, 238, 240);

      &:hover {
        color: rgb(25, 32, 51);
        background-color: rgb(238, 238, 240);
        border-top-color: rgb(223, 224, 229);
      }

      svg {
        opacity: 0.8;
      }
    }
  }

  @media (max-width: 768px) {
    .MuiPaper-root {
      width: 100%;
    }

    .newVideo-menu-section {
      width: 100%;
    }
  }
}

.sidebar-menu {
  .MuiBackdrop-root {
    background-color: rgba(25, 32, 51, 0.298);
  }

  .MuiPaper-root {
    border-radius: 0.9375rem;

    .MuiList-root {
      padding-top: 0px !important;
    }
  }
}

.line {
  height: 1.5px;
  width: 100%;
  background: rgb(238, 238, 240);
  margin: 0.5rem 0px 0px;
}