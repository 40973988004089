.custom-template-wrapper {
  display: flex;
  flex-direction: column;
  border-top: 2px solid rgb(223, 224, 229);
  // background: radial-gradient(115.38% 2286.5% at 83.74% 0%, rgba(255, 184, 0, 0.06) 0%, rgba(255, 214, 0, 0) 100%) white;
  width: calc(100% - 1px);
  height: calc(100% - 8px);

  .custom-template-content-wrapper {
    padding: 2rem;
    width: 100%;
    align-self: center;

    .custom-template-content-header {
      font-family: Inter, sans-serif;
      font-size: 0.9375rem;
      line-height: 1.25rem;
      letter-spacing: 0px;
      font-weight: 500;
      margin: 0px 0px 6px;
    }

    .custom-template-content-description {
      font-family: Inter, sans-serif;
      line-height: 1rem;
      letter-spacing: 0px;
      font-weight: 500;
      color: rgb(130, 136, 154);
      font-size: 14px;
      margin: 0px 0px 16px;
    }

    .custom-template-content-createBtn {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      width: 189px;
      background-color: rgb(255, 163, 26);
      border: none;

      color: rgb(255, 255, 255);
      transition: box-shadow 0.2s ease-in-out 0s;
      border-radius: 10px;
      height: 48px;
      padding: 0px 16px;
      min-width: 87px;
      align-items: center;
      user-select: none;
      font-family: Inter, sans-serif;
      font-size: 0.8125rem;
      line-height: 1rem;
      letter-spacing: 0px;
      font-weight: 500;

      &:hover {
        box-shadow: rgb(0 0 0 / 20%) 0px 5px 6px;
      }
    }
  }

  .custom-templates-body-wrapper {
    width: 100%;
    flex: 1;
    position: relative;
    overflow-y: auto;
    min-height: 500px;
    padding: 0px 30px;
    overflow-x: hidden;
    margin-top: 60px;

    .custom-templates-body {
      width: 100%;
      height: 100%;

      .template-item {
        position: relative;
        align-items: flex-start;
        border-radius: 10px;
        cursor: pointer;
        display: inline-flex;
        overflow: hidden;
        flex-direction: column;
        gap: 10px;

        .template-item-image {
          border-radius: 10px;
          display: flex;
          width: 100%;
          overflow: hidden;
          position: relative;

          &:hover {
            .hide-box {
              display: flex;
            }
          }

          .templateTitle-ThumbnailImageBackgroundOverlay {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: rgba(124, 124, 124, 0.3);
            z-index: 2;
          }
        }

        .templateTitle-TileInfoWrapper {
          display: grid;
          min-height: 40px;
          grid-template:
            "name buttons" min-content
            "time buttons" 1fr / 1fr min-content;

          .TileInfoWrapper-header {
            grid-area: name / name / name / name;
            font-family: Inter, sans-serif;
            font-size: 0.8125rem;
            line-height: 1rem;
            letter-spacing: 0px;
            font-weight: 500;
            // color: rgb(25, 32, 51);
            margin-bottom: 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .TileInfoWrapper-TileInfoTime {
            grid-area: time / time / time / time;
            font-family: Inter, sans-serif;
            font-size: 0.71875rem;
            line-height: 0.75rem;
            letter-spacing: 0.01em;
            font-weight: 400;
            color: rgb(160, 164, 178);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        img {
          width: 100%;
        }

        .hide-box {
          display: none;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #7f8180;
          opacity: 0.5;

          .loading-box {
            display: flex;
            width: 30px;
            height: 30px;
            max-width: 90%;
            max-height: 90%;

            flex-direction: column;
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: #272626;
            border-radius: 50%;
            align-items: center;
            justify-content: center;

            .loading-btn {
              width: 70% !important;
              height: 70% !important;
            }
          }
        }
      }

      .template-item1 {
        border-radius: 4px;
        box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
        background-color: rgb(255, 255, 255);
        padding: 10px;
        text-align: center;
      }
    }
  }

  .templates-next-preview-btn {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    padding-bottom: 20px;
    position: absolute;
    z-index: 10;
  }

  @media (max-width: 599px) {
    .custom-templates-body-wrapper {
      max-width: 300px;
      margin: auto;
      margin-top: 60px;
    }
  }
}

.templates-aspectRatioBtn-select-item {
  background-color: rgb(255, 255, 255);
  border: 0px;
  border-radius: 5px;
  color: rgb(25, 32, 51);
  padding: 8px 16px;
  text-align: left;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: rgb(0, 152, 253) !important;
    color: rgb(255, 255, 255);
  }

  svg {
    width: 16px;
    height: 16px;
    opacity: 0.7;
  }

  span {
    margin-right: 8px;
    margin-left: 8px;
    vertical-align: middle;
    font-size: 13px;
  }
}

.templates-aspectRatioBtn-select-item.Mui-selected {
  background-color: rgb(6, 106, 173) !important;
  color: rgb(255, 255, 255);
}


.templates-loading {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  background-color: #4a7c7c5c;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
}