.signUp-containner {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 2fr 3fr;
  font-family: Euclid, sans-serif;

  .signup-left {
    // background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 40px 24px;

    .signup-left-containner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 20rem;
      text-align: center;
      margin: 0px auto;
      flex-grow: 1;

      .signup-left-content {
        width: 100%;

        .signup-content-header {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            margin-bottom: 16px;
          }

          .signup-header-content {
            font-family: Euclid, sans-serif;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.25em;
            margin-bottom: 0.25rem;
          }

          .signup-header-footer {
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.2;
            margin-bottom: 0.5rem;
          }
        }

        .signup-content-body {
          width: 100%;
          display: flex;
          flex-direction: column;
          -webkit-box-align: center;
          align-items: center;
          font-family: Inter, sans-serif;

          .googleBtn {
            display: flex;
            flex-direction: row;
            align-items: center;

            font-family: Inter, sans-serif;
            font-size: 12px;
            font-weight: 600;
            background: none;
            padding: 16px 24px;
            width: 100%;
            border: 1px solid rgb(223, 224, 229);
            box-shadow: rgb(0, 0, 0, 10%) 0px 1px 2px;
            border-radius: 0.625rem;
            text-align: left;
            margin: 32px 0px;
            user-select: none;
            cursor: pointer;

            svg {
              margin-right: 40px;
            }
          }

          .signup-content-or {
            font-family: Inter, sans-serif;
            font-size: 12px;
            font-weight: 400;
            color: rgb(144, 148, 165);
            margin-bottom: 24px;
            display: flex;
            width: 100%;

            &::after {
              margin-left: 16px;
              content: "";
              width: 100%;
              height: 0.5rem;
              border-bottom: 1px solid rgb(223, 224, 229);
            }

            &::before {
              margin-right: 16px;
              content: "";
              width: 100%;
              height: 0.5rem;
              border-bottom: 1px solid rgb(223, 224, 229);
            }
          }

          .signup-content-input {
            text-align: left;
            margin-bottom: 24px;
            font-size: 0.75rem;
            display: flex;
            flex-direction: column;
            width: 100%;
          
            .email-input,
            .name-input,
            .surname-input {
              background: transparent;
              border-radius: 0.5rem;
              padding: 16px;
              font-size: inherit;
              border: 1px solid rgb(223, 224, 229);
              order: 2;
              // color: rgb(93, 100, 123);
              border-radius: 6px;

              &:focus-visible {
                outline: none;
                border-color: rgb(103, 193, 255);
              }

              &:hover {
                border-color: rgb(103, 193, 255);
              }
            }

            .email-input-label,
            .name-input-label,
            .surname-input-label {
              // color: rgb(25, 32, 51);
              margin-bottom: 8px;
              width: 100%;
              display: inline-block;
              order: 1;
            }
          }

          .signup-Btn {
            height: auto;
            padding: 16px;
            margin-bottom: 8px;
            width: 100%;
            background-color: rgb(25, 32, 51);
            color: rgb(255, 255, 255);
            border-color: rgb(25, 32, 51);
            transition: box-shadow 0.2s ease-in-out 0s;
            border-style: solid;
            border-width: 1px;
            border-radius: 10px;
            display: flex;
            min-width: 87px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;
            font-family: Inter, sans-serif;
            font-size: 12px;
            font-weight: 500;
            box-shadow: 0px 0px 5px 0px;
          }

          .signup-btn-footer-text {
            font-family: Inter, sans-serif;
            font-size: 12px;
            font-weight: 400;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            color: rgb(93, 100, 123);
            margin: 0px;
          }
        }
      }

      .signup-left-footer {
        .signup-content-footer {
          margin-top: 32px;
          text-align: center;

          .signup-content-footer-text {
            font-family: Inter, sans-serif;
            font-size: 12px;
            font-weight: 400;
            // color: rgb(25, 32, 51);
            margin-bottom: 2rem;

            a {
              text-decoration: none;
            }
          }

          .signup-content-footer-footer {
            a {
              text-decoration: none;
              border: none;
              background: none;
              color: rgb(0, 152, 253);
            }
          }
        }
      }
    }
  }

  .signup-right {
    background-image: url('./../../assets//image/signin-up/bgimg.png');
    background-position-x: center;
    background-position-y: center;
    background-size: 100%;
    background-repeat: no-repeat; /* This line is updated */
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: rgb(255, 255, 255);
  }
}