.content-containner {
  .breadcrumbs {
    margin-bottom: 32px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #dfe0e5;

    a {
      background-color: transparent;
      color: #0098fd;
      text-decoration: none;
    }

    .w-inline-block {
      max-width: 100%;
      display: inline-block;
    }

    .breadcrumb-text-block {
      display: inline-block;
      margin-right: 14px;
      -webkit-transition: .2s;
      transition: .2s;
      font-family: 'Euclidcirculara webfont', Arial, sans-serif;
      color: #5d647b;
      font-size: 14px;

      &:hover {
        color: #192033;
      }
    }

    .breadcrumb-arrow {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 24px;
      border-right: 1px solid #dfe0e5;
      border-bottom: 1px solid #dfe0e5;
      transform: rotate(-45deg);
    }
  }

.create-now {
  margin: 5px 0px;
  padding: 0.6rem 3rem;
  display: flex;
  align-items: center;
  user-select: none;
  width: unset;

  &:hover {
    color: #fff;
  }
}
  .std-hero-section {
    display: flex;

    .hero-grid {
      display: grid;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      grid-auto-columns: 1fr;
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      grid-template-columns: 0.75fr 1fr;
      grid-template-rows: auto;
      cursor: pointer;

      .hero-grid-text {
        display: flex;
        max-width: none;
        min-width: auto;
        padding-top: 48px;
        padding-right: 24px;
        flex-direction: column;
        align-items: flex-start;

        @media screen and (min-width: 1440px) {
          padding-right: 24px;
        }

        .hero-header-title {
          position: static;
          font-family: 'Euclidcirculara webfont', Arial, sans-serif;
          font-size: 48px;
          line-height: 1em;
          margin-top: 20px;
          font-weight: 700;
          top: 0;
          margin-bottom: 0;
          text-align: left;
        }

        .hero-content-title {
          font-family: 'Euclidcirculara webfont', Arial, sans-serif;
          font-size: 17px;
          font-weight: 300;
          overflow: visible;
          line-height: 1.4em;
          max-width: 100%;
          margin-top: 32px;
          text-align: left;
          margin-bottom: 48px;
        }

        .choose-btn {
          display: flex;
          box-shadow: 0px 4px 4px rgb(56 160 255 / 20%);
          width: auto;
          padding: 13px 13px 13px 10px;
          justify-content: center;
          align-items: center;
          border: 12px #000;
          border-radius: 1000px;
          background-color: #0098fd;
          transition: .3s;
          text-decoration: none;
          max-width: 100%;
          position: relative;

          .choose-btn-text {
            margin-top: 0;
            padding-top: 0;
            padding-bottom: 0;
            font-family: 'Euclidcirculara webfont', Arial, sans-serif;
            color: #fff;
            padding-left: 20px;
            font-size: 18px;
            line-height: 1.4em;
            font-weight: 400;
            text-decoration: none;
          }

          .choose-btn-img {
            box-shadow: 0px 100px 80px rgb(0 0 0 / 7%), 0px 41.7776px 33.4221px rgb(0 0 0 / 5%), 0px 22.3363px 17.869px rgb(0 0 0 / 4%), 0px 12.5216px 10.0172px rgb(0 0 0 / 4%), 0px 6.6501px 5.32008px rgb(0 0 0 / 3%), 0px 2.76726px 2.21381px rgb(0 0 0 / 2%), inset 0px 2px 1px #ffffff;
            display: flex;
            width: 50px;
            height: 50px;
            margin-left: 20px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-self: auto;
            flex: 0 0 auto;
            border-radius: 1000px;
            background-color: #fff;
          }

          .choose-file-btn {
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 1000px;
            opacity: 0;
            cursor: pointer;
          }
        }
      }

      .hero-img {
        position: relative;
        display: flex;
        width: auto;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 0 auto;
        padding-top: 64px;

        img {
          border: 0;
          vertical-align: middle;
          display: inline-block;
          max-width: 100%;
        }
      }

      .hero-img.update {
        padding-top: 64px;
      }

      @media screen and (max-width: 991px) {
        flex-direction: column;

        .hero-grid-text,
        .hero-img {
          grid-row-start: span 1;
          grid-row-end: span 1;
          grid-column-start: span 2;
          grid-column-end: span 2;
        }

        .hero-img {}
      }

      @media screen and (max-width: 479px) {
        grid-template-columns: 1.5fr;

        .hero-grid-text {
          padding-top: 16px;
          padding-right: 0;
        }

        .hero-img {
          order: -9999;
        }
      }

      @media screen and (max-width: 200px) {
        .hero-grid-text {
          .choose-btn {
            .choose-btn-text {
              display: none;
            }

            .choose-btn-img {
              margin: 0px;
            }
          }
        }
      }
    }

    .logos-grid {
      padding-top: 64px;
      opacity: .54;
      width: 75%;
      margin-right: auto;
      margin-left: auto;
      align-items: center;
      grid-template-rows: auto;
      display: grid;
      grid-auto-columns: 1fr;
      grid-row-gap: 16px;
      grid-column-gap: 16px;
      justify-items: center;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

      img {
        border: 0;
        vertical-align: middle;
        display: inline-block;
        max-width: 100%;
        box-sizing: border-box;
      }
    }
  }

  .text-section-block {
    column-count: 2;
    column-gap: 64px;
    column-rule-width: 0px;

    p {
      display: block;
      font-family: 'Euclidcirculara webfont', Arial, sans-serif;
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
      letter-spacing: .5px;
    }
  }

  .how-to-div-item {
    .how-to-img {
      max-width: 100%;
      object-fit: cover;
    }

    .how-to-content {
      margin-top: 16px;
      margin-bottom: 32px;
      font-family: 'Euclidcirculara webfont', Arial, sans-serif;
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
      letter-spacing: .5px;
    }

    @media screen and (max-width: 767px) {
      grid-row-start: span 1;
      grid-row-end: span 1;
      grid-column-start: span 3;
      grid-column-end: span 3;
      align-self: auto;
      justify-self: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 400px;
      }
    }
  }

  .online-video-section {
    .w-inline-block {
      background-color: transparent;
      color: #0098fd;
      text-decoration: none;

      img {
        border: 0;
        vertical-align: middle;
        display: inline-block;
        max-width: 100%;
        width: 100px;
      }
    }
  }

  .content-sec-grid-item {
    margin-top: 24px;
    margin-bottom: 100px;
    grid-column-gap: 32px;
    grid-template-rows: auto;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 16px;

    .content-item-text {
      display: flex;
      padding-right: 60px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      align-items: center;

      .left-textt-block {
        margin-top: 16px;
        font-size: 16px;
        text-align: left;

        strong {
          font-weight: 400;
        }

        p {
          margin-bottom: 16px;
          margin-top: 16px;
          font-family: 'Euclidcirculara webfont', Arial, sans-serif;
          font-size: 20px;
          line-height: 30px;
          font-weight: 400;
          letter-spacing: .5px;
        }
      }
    }

    .content-item-img {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        visibility: visible;
        max-width: none;
        border: 0;
        vertical-align: middle;
        display: inline-block;
      }
    }

    @media screen and (max-width: 991px) {

      .content-item-text,
      .content-item-img {
        grid-row-start: span 1;
        grid-row-end: span 1;
        grid-column-start: span 2;
        grid-column-end: span 2;
      }

      .contet-item-order-text {
        -webkit-order: -9999;
        order: -9999;
      }
    }
  }

  .help-question-sec {
    width: 100%;

    .faq-grid {
      width: 100%;
      display: grid;
      grid-auto-columns: 1fr;
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      grid-template-columns: 0.5fr 1fr;
      grid-template-rows: auto;

      .faq-grid-left {
        .question-icon {
          .circle-shadow {
            box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 100px 80px rgb(0 0 0 / 3%), 0px 41.7776px 33.4221px rgb(0 0 0 / 2%), 0px 22.3363px 17.869px rgb(0 0 0 / 2%), 0px 12.5216px 10.0172px rgb(0 0 0 / 2%), 0px 6.6501px 5.32008px rgb(0 0 0 / 1%), 0px 2.76726px 2.21381px rgb(0 0 0 / 1%);
            display: flex;
            width: 88px;
            height: 88px;
            margin-bottom: 20px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 1000px;
          }
        }
      }

      .faq-grid-right {
        .faq-grid-item {
          margin-bottom: 0;
          padding: 24px 30px 14px;
          border-top: 1px solid #dfe0e5;
          border-radius: 0;
          width: 100%;

          .faq-item-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .faq-header-text {
              font-weight: 600;
              margin-top: 0;
              margin-bottom: 0;
              flex: 1;
              font-size: 20px;
              line-height: 30px;
              cursor: pointer;
            }

            .faq-header-icon {
              color: #192033;
              font-size: 32px;
              line-height: 30px;
              font-weight: 300;
              cursor: pointer;

              svg {
                transition: 0.2s ease-out;
              }
            }
          }

          .faq-item-body {
            overflow: hidden;
            margin-top: 10px;
            margin-right: 30px;
            max-width: 590px;
            transition: height 0.15s ease-out;

            p {
              margin-top: 16px;
              margin-bottom: 32px;
              font-family: 'Euclidcirculara webfont', Arial, sans-serif;
              font-size: 20px;
              line-height: 30px;
              font-weight: 400;
              letter-spacing: .5px;
            }
          }
        }
      }

      @media screen and (max-width: 991px) {
        .faq-grid-left {
          grid-row-start: span 1;
          grid-row-end: span 1;
          grid-column-start: span 2;
          grid-column-end: span 2;
        }

        .faq-grid-right {
          grid-row-start: span 1;
          grid-row-end: span 1;
          grid-column-start: span 2;
          grid-column-end: span 2;
        }
      }
    }
  }

  .Discover-section {
    .header-title {
      display: flex;
      margin-bottom: 32px;
      padding-bottom: 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .discover-body-items {
      margin-bottom: 0;
      justify-content: flex-start;
      display: flex;
      flex-wrap: wrap;
      grid-auto-columns: 1fr;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;

      .discover-body-item {
        padding-right: 8px;
        padding-bottom: 16px;

        .discover-link {
          padding: 10px 18px;
          border: 1px solid rgba(25, 32, 51, .16);
          border-radius: 10px;
          background-color: rgba(255, 255, 255, 0);
          transition: .2s;
          font-family: 'Euclidcirculara webfont', Arial, sans-serif;
          color: #192033;
          display: inline-block;
          line-height: inherit;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }

  .more-than-update {
    position: relative;
    display: flex;
    overflow: hidden;
    max-width: 1232px;
    min-height: 200px;
    margin-right: auto;
    margin-left: auto;
    padding: 64px 96px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px #000;
    border-radius: 20px;
    background-image: linear-gradient(180deg, #f7f7f8 10%, #fff 80%);

    .more-than-update-text {
      position: relative;
      z-index: 1;
      max-width: 769px;
      margin-bottom: 32px;
      text-align: center;

      h2 {
        margin-top: 0;
        margin-bottom: 24px;
        color: #192033;
        font-weight: 400;
        font-size: 32px;
        line-height: 1.3em;
      }

      p {
        color: #192033;
        line-height: 150%;
        margin-top: 16px;
        margin-bottom: 32px;
        font-family: Euclidcirculara webfont;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: .5px;
      }
    }

    .choose-btn {
      display: flex;
      box-shadow: 0px 4px 4px rgb(56 160 255 / 20%);
      width: auto;
      padding: 13px 13px 13px 10px;
      justify-content: center;
      align-items: center;
      border: 12px #000;
      border-radius: 1000px;
      background-color: #0098fd;
      transition: .3s;
      text-decoration: none;
      max-width: 100%;

      .choose-btn-text {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
        font-family: 'Euclidcirculara webfont', Arial, sans-serif;
        color: #fff;
        padding-left: 20px;
        font-size: 18px;
        line-height: 1.4em;
        font-weight: 400;
        text-decoration: none;
      }

      .choose-btn-img {
        box-shadow: 0px 100px 80px rgb(0 0 0 / 7%), 0px 41.7776px 33.4221px rgb(0 0 0 / 5%), 0px 22.3363px 17.869px rgb(0 0 0 / 4%), 0px 12.5216px 10.0172px rgb(0 0 0 / 4%), 0px 6.6501px 5.32008px rgb(0 0 0 / 3%), 0px 2.76726px 2.21381px rgb(0 0 0 / 2%), inset 0px 2px 1px #ffffff;
        display: flex;
        width: 50px;
        height: 50px;
        margin-left: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: auto;
        flex: 0 0 auto;
        border-radius: 1000px;
        background-color: #fff;
      }
    }

    .html-embed-6 {
      position: relative;
      z-index: 1;

      img {
        border: 0;
        vertical-align: middle;
        display: inline-block;
        max-width: 100%;
      }
    }

    .more-than-pattern-bg {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      overflow: visible;
      // background-image: url('../../image/more/pattern-bg.png');
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  @media screen and (max-width: 767px) {
    .text-section-block {
      column-count: 1;
    }
  }
}