.project-view-wrapper {
  position: absolute;
  width: 100vw;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: row;

  .view-body-section {
    flex: 1;
    display: flex;
    flex-direction: column;

    .header-section {
      display: flex;
      flex-direction: row;
      width: 100%;
      min-height: 4.5rem;
      padding: 1rem 2rem;
      margin: 0px;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      .header-left {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        overflow: hidden;

        .home-icons {
          display: flex;
          align-items: center;
          color: rgb(25, 32, 51);
          pointer-events: auto;
          cursor: pointer;

          svg {
            width: 24px;
            height: 24px;
            color: rgb(25, 32, 51);
          }

          span {
            height: 1rem;
            border: 1px solid rgb(223, 224, 229);
            border-radius: 10px;
            margin: 0px 1rem;
          }

          img {
            height: 20px;
            width: auto;
            cursor: pointer;
            color: rgb(25, 32, 51);
          }
        }

        .project-title {
          display: flex;
          align-items: baseline;
          margin-left: 16px;
          max-width: calc((100vw - 376px) - 500px);
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          h2 {
            cursor: default;
            flex: 1 1 0px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            min-width: 35%;
            font-family: Inter, sans-serif;
            font-size: 0.9375rem;
            line-height: 1.25rem;
            letter-spacing: 0px;
            font-weight: 400;
            color: rgb(93, 100, 123);
          }
        }
      }

      .header-right {
        display: flex;
        align-items: center;
        flex: 0 0 auto;

        .login-btn {
          display: flex;
          border-radius: 8px;
          padding: 0.125rem 16px;
          color: rgb(93, 100, 123);
          align-items: center;
          justify-content: center;
          font-family: Inter, sans-serif;
          font-size: 0.8125rem;
          line-height: 1rem;
          letter-spacing: 0px;
          font-weight: 500;
          cursor: pointer;

          &:hover {
            color: rgb(0, 152, 253);
            background-color: rgb(239, 249, 255);
          }
        }

        .share-btn {
          display: flex;
          gap: 10px;
          min-width: 87px;
          height: 2.5rem;
          margin-left: 1rem;
          padding: 0px 16px;
          border-radius: 10px;
          color: rgb(255, 255, 255);
          background: rgb(0, 152, 253);
          border: 1px solid rgb(0, 152, 253);
          transition: border 0.2s ease-in-out 0s;
          justify-content: space-between;
          align-items: center;
          user-select: none;
          white-space: nowrap;
          cursor: pointer;

          font-family: Inter, sans-serif;
          font-size: 0.8125rem;
          line-height: 1rem;
          letter-spacing: 0px;
          font-weight: 500;

          &:hover {
            background: rgb(18, 134, 241);
            border: 1px solid rgb(103, 193, 255);
            box-shadow: rgb(0 0 0 / 20%) 0px 5px 6px;
          }
        }
      }
    }

    .video-section {
      width: 100%;
      padding: 0px 2rem;

      .video-container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        background: rgb(0, 0, 0);
        max-height: 100%;
        overflow: hidden;
        isolation: isolate;
        cursor: unset;

        &::before {
          content: "";
          float: left;
          padding-top: 56.25%;
        }

        &::after {
          content: "";
          display: block;
          clear: both;
        }

        video {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
        }

        .upload-progressing {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;

          display: flex;
          flex-direction: column;

          .progress-title {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h2 {
              color: rgb(170, 169, 160);
              font-size: 30px;
            }
          }

          .progress-bar {
            width: 100%;
            height: auto;
            margin-bottom: 2px;

            .MuiLinearProgress-root {
              height: 6px;
            }
          }
        }

        .download-progressing {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;

          display: flex;
          flex-direction: column;
          background-color: black;
          .progress-title {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h2 {
              color: rgb(170, 169, 160);
              font-size: 30px;
            }
          }

          .progress-bar {
            width: 100%;
            height: auto;
            margin-bottom: 2px;

            .MuiLinearProgress-root {
              height: 6px;
            }
          }
        }
      }
    }

    .footer-section {
      display: flex;
      opacity: 1;
      width: 100%;
      padding: 0rem 2rem;
      margin: 1rem 0rem;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;

      .footer-contain {
        display: flex;
        flex-direction: row;
        gap: 16px;

        h2 {
          display: flex;
          width: 56px;
          height: 56px;
          color: rgb(0, 0, 0);
          background-color: rgb(247, 247, 247);
          border-radius: 100%;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          font-family: Inter, sans-serif;
          font-size: 0.8125rem;
          line-height: 1rem;
          letter-spacing: 0px;
          font-weight: 400;
          user-select: none;
        }

        span {
          display: flex;
          flex-direction: column;

          h3 {
            color: rgb(25, 32, 51);
            margin-bottom: 8px;
            font-family: Inter, sans-serif;
            font-size: 0.9375rem;
            line-height: 1.25rem;
            letter-spacing: 0px;
            font-weight: 500;
          }

          .content {
            display: flex;
            flex-direction: row;
            align-items: center;

            h5 {
              display: flex;
              align-items: center;
              color: rgb(93, 100, 123);
              font-family: Inter, sans-serif;
              font-size: 0.8125rem;
              line-height: 1rem;
              letter-spacing: 0px;
              font-weight: 500;

              svg {
                width: 12px;
                height: 12px;
                margin-right: 5px;
                color: rgb(93, 100, 123);
              }
            }

            h6 {
              color: rgb(144, 148, 165);
              margin-left: 16px;
              font-family: Inter, sans-serif;
              font-size: 0.8125rem;
              line-height: 1rem;
              letter-spacing: 0px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  .view-sidebar-section {
    display: flex;
    flex-direction: column;
    width: 376px;
    padding: 0px 24px;
    box-shadow: rgb(144 148 165 / 15%) -1px 0px 0px, rgb(93 100 123 / 15%) 0px 0px 8px;
    overflow-y: auto;

    .sidebar-header-contain {
      position: relative;
      text-align: center;
      padding-top: 16px;

      .header-background {
        position: absolute;
        width: 100%;
        height: 156px;
        top: -78px;
        background: radial-gradient(191.79% 191.79% at 50% 100%, rgba(255, 110, 110, 0) 0%, rgb(32, 227, 145) 100%);
        filter: blur(22px);
        opacity: 0.4;
      }

      svg {
        width: 64px;
        height: 64px;
        margin-top: 32px;
        margin-bottom: 16px;
      }

      h5 {
        font-family: Inter, sans-serif;
        font-size: 0.9375rem;
        letter-spacing: 0px;
        font-weight: 600;
        color: rgb(25, 32, 51);
        display: block;
        line-height: 22px;
      }
    }

    .sidebar-link-contain {
      display: flex;
      flex-direction: row;
      padding: 0px;
      margin: 0px;
      justify-content: space-between;

      margin-top: 24px;
      margin-left: 16px;
      margin-right: 16px;
      user-select: none;

      .link-item {
        display: flex;
        flex-direction: column;
        list-style: none;
        height: 4rem;
        align-items: center;
        cursor: pointer;

        span {
          height: 2.5rem;
          width: 2.5rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: 1.25rem;
            width: 1.25rem;
          }
        }

        h2 {
          font-family: Inter, sans-serif;
          font-size: 0.8125rem;
          line-height: 1rem;
          letter-spacing: 0px;
          font-weight: 500;
          color: rgb(93, 100, 123);
          margin-top: 0.5rem;
        }
      }
    }

    .sidebar-content-section {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      background: rgb(247, 247, 248);
      border-radius: 1.25rem;
      border: 0.09375rem solid rgb(238, 238, 240);

      .content-body-contain {
        display: flex;
        flex-direction: column;
        min-width: 18rem;
        padding: 24px;
        align-items: center;
        box-sizing: border-box;

        .time-count-section {
          display: flex;
          flex-direction: row;
          width: 100%;
          padding: 0.75rem;
          border-radius: 1rem;
          background-color: rgb(255, 255, 255);
          box-shadow: rgb(0 0 0 / 3%) 0px 100px 80px, rgb(0 0 0 / 2%) 0px 41.7776px 33.4221px, rgb(0 0 0 / 2%) 0px 22.3363px 17.869px, rgb(0 0 0 / 2%) 0px 12.5216px 10.0172px, rgb(0 0 0 / 1%) 0px 6.6501px 5.32008px, rgb(0 0 0 / 1%) 0px 2.76726px 2.21381px;
          align-items: center;
          box-sizing: border-box;

          div {
            flex: 1 1 0%;
            display: flex;
            flex-direction: column;
            justify-content: left;
            align-items: center;

            h3 {
              font-family: Inter, sans-serif;
              font-size: 1.125rem;
              letter-spacing: 0px;
              font-weight: 600;
              line-height: 1.4375rem;
              text-align: center;
            }

            h4 {
              font-family: Inter, sans-serif;
              font-size: 0.8125rem;
              letter-spacing: 0px;
              font-weight: 500;
              line-height: 1rem;
              text-align: center;
              color: rgba(83, 89, 110, 0.498);
            }
          }

          span {
            flex: 0 0 auto;
            width: 0rem;
            height: 1.125rem;
            margin: 0px 8px;
            border-left: 0.0625rem solid rgba(83, 89, 110, 0.098);
          }
        }

        .content-section {
          margin-top: 24px;
          display: flex;
          flex-flow: row wrap;
          place-content: center;

          h2,
          h3 {
            flex: 0 0 auto;
            white-space: pre;

            font-family: Inter, sans-serif;
            font-size: 0.8125rem;
            letter-spacing: 0px;
            font-weight: 500;
            line-height: 1.125rem;
          }
        }

        .signUp-btn {
          display: flex;
          width: 100%;
          min-width: 87px;
          height: 48px;
          padding: 0px 16px;
          margin-top: 24px;
          border-radius: 10px;
          border: 1px solid rgb(0, 152, 253);
          color: rgb(255, 255, 255);
          background: rgb(0, 152, 253);
          transition: border 0.2s ease-in-out 0s;
          justify-content: center;
          align-items: center;
          user-select: none;
          cursor: pointer;

          font-family: Inter, sans-serif;
          font-size: 0.8125rem;
          line-height: 1rem;
          letter-spacing: 0px;
          font-weight: 500;
        }
      }

      .content-footer-contain {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        color: rgb(25, 32, 51);
        border-top: 0.1rem solid rgb(238, 238, 240);
        text-align: left;
        cursor: pointer;

        button {
          width: 100%;
          margin: 0.75rem 0px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border: unset;
          background: unset;

          h2 {
            font-family: Inter, sans-serif;
            font-size: 0.8125rem;
            line-height: 1rem;
            letter-spacing: 0px;
            font-weight: 600;
          }

          svg {
            width: 16px;
            width: 16px;
            color: rgb(144, 148, 165);
            flex-basis: auto;
            stroke-width: 2.25px;
            overflow: visible;
          }
        }

        .helper-contain {
          padding: 0px;
          margin: 0px 0px 0.75rem 16px;
          color: rgb(93, 100, 123);

          font-family: Inter, sans-serif;
          font-size: 0.8125rem;
          letter-spacing: 0px;
          font-weight: 500;
          line-height: 24px;

          li {
            padding-left: 4px;

            &::marker {
              color: rgba(144, 148, 165, 0.498);
            }
          }

          span {
            margin-left: -8px;
            margin-bottom: 0.75rem;
            color: rgb(93, 100, 123);

            font-family: Inter, sans-serif;
            font-size: 0.8125rem;
            line-height: 1rem;
            letter-spacing: 0px;
            font-weight: 500;
          }
        }
      }
    }

    .sidebar-button-group {
      display: flex;
      flex-direction: column;
      margin-top: 1.5625rem;
      gap: 12px;

      button {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        min-width: 87px;
        height: 3rem;
        padding: 0px 16px;
        color: rgb(25, 32, 51);
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        border: 1px solid rgb(223, 224, 229);
        box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px;
        transition: border 0.2s ease-in-out 0s;
        cursor: pointer;

        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
        user-select: none;

        &:hover {
          color: rgb(0, 152, 253);
          border: 1px solid rgb(103, 193, 255);
          box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px;
        }

        h2 {
          font-family: Inter, sans-serif;
          font-size: 0.8125rem;
          line-height: 1rem;
          letter-spacing: 0px;
          font-weight: 500;
        }

        svg {
          width: 16px;
          height: 16ppx;
          stroke-width: 2.25px;
          overflow: visible;
        }
      }
    }

    .watermark-btn {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      min-width: 87px;
      height: 3rem;
      padding: 0px 16px;
      margin-top: 2.25rem;
      border-radius: 10px;
      border: 1.5px solid rgba(255, 163, 25, 0.5);
      box-shadow: rgb(255 163 25 / 20%) 0px 1px 1px;
      color: rgb(25, 32, 51);
      background-color: rgb(255, 255, 255);
      transition: border 0.2s ease-in-out 0s;
      justify-content: space-between;
      align-items: center;
      user-select: none;
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        border: 1.5px solid rgb(255, 163, 25);
        box-shadow: rgb(255 163 25 / 20%) 0px 1px 1px;
        color: rgb(25, 32, 51);
      }

      h2 {
        font-family: Inter, sans-serif;
        font-size: 0.8125rem;
        line-height: 1rem;
        letter-spacing: 0px;
        font-weight: 600;
      }

      svg {
        width: 16px;
        height: 16px;

        path {
          fill: rgb(255, 195, 105);
        }
      }
    }

    .sidebar-footer {
      position: fixed;
      width: calc(328px);
      right: 24px;
      bottom: 0px;
      padding-top: 40px;
      padding-bottom: 16px;
      color: rgb(93, 100, 123);
      background: linear-gradient(to top, rgb(255, 255, 255), rgb(255, 255, 255) 40%, transparent);
      text-align: center;

      h5 {
        font-family: Inter, sans-serif;
        font-size: 0.8125rem;
        line-height: 1rem;
        letter-spacing: 0px;
        font-weight: 500;

        a {
          text-decoration: none;
          color: rgb(0, 152, 253);
          cursor: pointer;

          &:hover {
            color: #0056b3;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    .view-body-section {
      flex: none;

      .header-section {
        margin: 8px 0px;
        padding: 0px 8px;
        min-height: 1.5rem;
      }
    }

    .view-sidebar-section {
      flex: none;
      width: 100%;
      margin-bottom: 50px;

      .sidebar-footer {
        display: none;
      }
    }
  }

  @media (max-width: 600px) {
    .view-body-section {
      .header-section {
        .header-left {
          .home-icons {

            span,
            img {
              display: none;
            }
          }
        }

        .header-right {
          .login-btn {
            display: none;
          }

          .share-btn {
            margin: unset;
          }
        }
      }
    }
  }
}