.text-section-block {
  .text-section-ul {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.5px;
    font-family: "Euclidcirculara webfont", Arial, sans-serif;

    li {
      list-style-position: inside;
      list-style-type: circle;

      span {
        font-weight: 700;
      }
    }
  }
}