.Header,
.pricing_header {
	display: flex;
	width: 100%;
	padding: 5px 20px;
	margin-left: auto;
	margin-right: auto;
	height: 72px;
	justify-items: center;
	align-items: center;
	flex-direction: row;

	.menu-containner {
		flex: 1;
		display: flex;
		flex-direction: row;
		height: 100%;

		.menu-left {
			display: flex;
			align-items: center;
			margin-left: 24px;

			.Menu_item_header {
				display: flex;
				position: relative;
				height: 100%;
				cursor: pointer;

				.menu_title {
					// color: #192033;
					font-size: 16px;
					font-weight: 400;
					letter-spacing: .05em;
				}

				.menu_item_icon {
					color: rgb(255, 255, 255);
					opacity: .51;
					margin-right: 20px;
				}

				._hidden_card {
					position: absolute;
					width: 100%;
					height: 100%;
					z-index: 1500;
				}

				&:hover {

					.menu_title,
					.menu_item_icon {
						color: #3b70ff;
					}
				}
			}
		}

		.menu-right {
			display: flex;
			align-items: center;
			height: 100%;

			a {
				text-decoration: none;
				display: flex;
				height: 100%;
				// color: #192033;
				padding-right: 36px;
				padding-left: 24px;
				font-size: 16px;
				font-weight: 400;

				&:hover {
					color: #0098fd;
				}

				@media screen and (max-width: 991px) {
					padding-right: 20px;
					padding-left: 20px;
				}
			}

			.login-containner {
				background-color: #eeeef0;
				height: 70%;
				border-radius: 8px;
				color: #192033;
				margin-right: 10px;
				cursor: pointer;
				user-select: none;

				&:hover {
					color: #192033;
				}
			}

			.signOut-containner {
				background-color: #eeeef0;
				height: 70%;
				border-radius: 8px;
				color: #192033;
				margin-right: 10px;
				cursor: pointer;
				user-select: none;

				&:hover {
					color: #192033;
				}
			}

			.login-btn {
				display: flex;
				align-items: center;
				justify-items: center;
				cursor: pointer;
			}

			.signOut-btn {
				display: flex;
				align-items: center;
				justify-items: center;
				cursor: pointer;
			}

			.signUp-containner {
				background-color: #192033;
				color: #fff;
				height: 70%;
				user-select: none;
				width: unset;

				&:hover {
					color: #fff;
				}
			}

			.signUp-btn {
				display: flex;
				align-items: center;
				justify-items: center;
				cursor: pointer;
			}

			.signIn-btn {
				display: flex;
				align-items: center;
				justify-items: center;
				cursor: pointer;
			}
		}
	}

	.mobile-container {
		display: none;

		.mobileMenuIcon {
			padding: 10px;
			cursor: pointer;
		}
	}
}

.Header {
	max-width: 1300px;

	.logoImg {
		margin-right: 10px;

		a {
			width: 100%;
			height: 100%;

			img {
				cursor: pointer;
				width: 124px;
			}
		}

	}

	.menu-containner {
		.menu-left {
			flex: 1;
		}

		.menu-right {
			.pricing-btn {
				display: flex;
				align-items: center;
				justify-items: center;
				cursor: pointer;
			}

			.signUp-containner {
				border-radius: 8px;
			}
		}
	}

	@media screen and (max-width: 900px) {
		.mobile-container {
			display: block;
		}

		.menu-containner {
			.Header_Menu_item {
				display: none;
			}
		}
	}

	@media screen and (max-width: 768px) {
		.menu-containner {
			.menu-right {

				.pricing-btn {
					display: none;
				}
			}
		}
	}

	@media screen and (max-width: 479px) {
		height: 60px;
		.language-btn{
			display: none !important;
		}
		.logoImg {
			a {
				width: auto;
			}

			img {
				width: 100% !important;
			}
		}

		.menu-containner {
			justify-content: end;

			.menu-left {
				display: none;
			}

			.menu-right {
				a {

					&:nth-child(1) {
						display: none;
					}
				}

				.signUp-containner {
					padding: 8px 12px;
					font-size: 12px;
					line-height: 16px;
					font-weight: 400;
					white-space: nowrap;
				}
			}
		}
	}
}

.pricing_header {
	margin-bottom: 20px;

	.menu-containner {
		justify-content: space-between;

		.menu-left {
			a {
				text-decoration: none;
				display: flex;
				height: 100%;
				color: #192033;
				padding-right: 24px;
				font-size: 16px;
				font-weight: 400;

				&:hover {
					color: #0098fd;
				}

				@media screen and (max-width: 991px) {
					padding-right: 20px;
					padding-left: 20px;
				}
			}

			.pricing-btn {
				display: flex;
				align-items: center;
				justify-items: center;
				cursor: pointer;
			}
		}

		.menu-right {
			.signUp-containner {
				border-radius: 999px;
				font-weight: bold;
			}
		}

		.logoImg {
			display: flex;
			align-items: center;

			a {
				width: 100%;

				img {
					cursor: pointer;
					width: 124px;
				}
			}

		}
	}

	.mobile-container {
		.mobileMenuIcon {
			height: 62px;
			width: 62px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid rgba(0, 0, 0, 0.1);
			background: rgba(200, 200, 200, 0.06);
		}
	}

	@media screen and (max-width: 1025px) {
		.mobile-container {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: 10px;
		}

		.menu-containner {
			.menu-left {
				display: none;
			}

			.menu-right {
				display: none;
			}
		}
	}
}

.menu_item_list {
	.itemlistcontainner {
		// background-color: #fff;
		border-radius: 10px;
		display: flex;
		flex-direction: row;
		padding: 20px;
		box-shadow: 0px 0px 2px 1px;

		.itemList_items {
			display: flex;
			flex-direction: column;
			min-width: 140px;
			align-items: flex-start;
			padding: 0px 10px;

			.item-header {
				width: 100%;
				padding: 8px;
				transition: color .2s;
				font-size: 14px;
				line-height: 20px;
				font-weight: 500;
				color: #192033;
			}

			a {
				width: 100%;
				text-decoration: none;
			}

			.List_item {
				padding: 8px;
				font-family: 'Euclidcirculara webfont', Arial, sans-serif;
				font-size: 13px;
				text-align: left;
				overflow: visible;
				width: 100%;
				border-radius: 6px;
				transition-property: background-color;
				// color: rgba(38, 40, 43, .7);
				font-weight: 400;
				text-transform: capitalize;
				white-space: normal;
				cursor: pointer;
				user-select: none;

				&:hover {
					background-color: #f8f8f8;
				}
			}
		}
	}
}

.mobile_menu_item_list {
	.css-xxrpyt-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
		max-height: calc(100% - 73px);
		overflow-y: auto;
		overflow-x: hidden;

		&::-webkit-scrollbar {
			width: 1px;
		}

		@media screen and (max-width: 479px) {
			max-height: calc(100% - 58px);
		}
	}

	.mobile_itemlist_containner {
		display: flex;
		flex-direction: column;
		border-radius: 5px;
		background-color: #fff;
		padding: 20px;
		width: 100vw;

		.Mobile_menu_item {
			width: 100%;
			display: flex;
			flex-direction: column;

			.parent_item,
			.parent_item2 {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				justify-items: center;

				color: #192033;
				font-size: 16px;
				user-select: none;
				cursor: pointer;

				svg {
					color: #9094a5;
				}
			}

			.parent_item {
				padding: 20px 0px;
			}

			.parent_item2 {
				padding: 10px 0px;

				.mobileitem2 {
					padding: 10px 0px;
					text-decoration: none;
					color: #192033;
					width: 100%;

					&:hover {
						color: #0098fd;
					}
				}
			}

			.children_items {
				margin-bottom: 20px;

				.children_item {
					.children_item_header {
						padding: 8px 0px;
						margin-top: 20px;

						font-family: Euclidcirculara webfont;
						padding-left: 8px;
						transition: color .2s;
						font-size: 14px;
						line-height: 20px;
						font-weight: 600;
						color: #192033;
						cursor: pointer;
					}

					.children_item_body {
						padding-left: 8px;

						.child_body_item {
							display: flex;
							flex-direction: column;

							a {
								padding: 6px;
								text-decoration: none;
								border-radius: 6px;
								transition-property: background-color;
								color: rgba(38, 40, 43, .7);
								text-transform: capitalize;
								white-space: normal;
								cursor: pointer;

								&:hover {
									background-color: #f8f8f8;
								}
							}

							label {
								font-family: Euclidcirculara webfont;
								font-size: 14px;
								text-align: left;
								overflow: visible;
								font-weight: 400;
							}
						}
					}

					&:nth-child(1) {
						.children_item_header {
							margin-top: 0px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 991px) {
			.Mobile_menu_item {
				.parent_item {}
			}
		}
	}
}

.default-menu {
	.MuiPaper-root {
		border-radius: 0.5rem;
		outline: unset !important;
		background-color: unset !important;
		box-shadow: 0px 0px 3px 0px;
		// box-shadow: unset !important;
	}

	.MuiList-root {
		margin: unset !important;
		padding: unset !important;
	}
}