@import url(./global.scss);

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  line-height: 150%;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overscroll-behavior-y: contain;
}

.rCS1rb3a7w {
  z-index: 8 !important;

  .moveable-control {
    z-index: 8 !important;
  }
}

.default-input {
  resize: none;
  outline: unset;
  background-color: unset;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

ul {
  list-style-type: disc;
}

.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.default-modal-wrapper {
  .default-modal {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    outline: unset;
  }
}