.blog-wrapper {
  gap: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 100px;

  .blog-container {
    .blog-item {
      cursor: pointer;
      box-shadow: #11111a1a 0px 1px 0px, #11111a1a 0px 8px 24px, #11111a1a 0px 16px 48px;
    }
  }

  @media screen and (max-width: 1536px) {
    padding: 30px 70px;
  }

  @media screen and (max-width: 1280px) {
    padding: 20px 50px;
  }

  @media screen and (max-width: 1024px) {}

  @media screen and (max-width: 992px) {
    padding: 10px 30px;
  }

  @media screen and (max-width: 768px) {}

  @media screen and (max-width: 640px) {
    padding: 0px 20px;
  }

  @media screen and (max-width: 350px) {}
}