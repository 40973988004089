.empty-project-popup-wrapper {
  .MuiPaper-root {
    margin: unset;
    border-radius: 1.25rem;

    max-height: 93vh;
    max-width: 90vw;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 16px;
  }

  .ep-popup-cotain {
    display: flex;
    flex-direction: row;
    background: none;
    cursor: default;
    overflow: hidden;

    .popup-close-btn {
      border: none;
      background: none;
      position: absolute;
      cursor: pointer;
      right: 1.5rem;
      top: 1rem;
      color: rgb(144, 148, 165);
      z-index: 11;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    .ep-popup-left-contain {
      display: flex;
      min-width: 14.375rem;
      // background-color: rgb(247, 247, 248);
      flex-direction: column;
      position: relative;
      height: 100%;

      h2 {
        font-family: Inter, sans-serif;
        font-size: 1.5rem;
        line-height: 1.75rem;
        letter-spacing: -0.005em;
        font-weight: 600;
        padding: 3.5rem 2rem 2.5rem;
      }

      .popup-left-menuitems-wrapper {
        padding: 0px;
        margin-bottom: 1rem;

        .popup-left-menuitem {
          text-decoration: none;
          display: flex;
          margin: 4px 0px;
          padding: 0.5rem 2rem;
          white-space: nowrap;
          align-items: center;
          cursor: pointer;

          border-left: 3px solid transparent;
          color: rgb(144, 148, 165);

          &:hover {
            color: rgb(25, 32, 51);
          }

          span {
            font-family: Inter, sans-serif;
            font-size: 0.8125rem;
            line-height: 1rem;
            letter-spacing: 0px;
            font-weight: 500;
          }

          .popup-menuitem-badge {
            background-color: rgb(242, 164, 89);
            border-radius: 4px;
            color: rgb(255, 255, 255);
            display: inline-block;
            font-family: Inter, sans-serif;
            font-size: 9px;
            font-weight: 900;
            line-height: 11px;
            letter-spacing: 0.5px;
            margin: 4px 8px;
            padding: 2px 4px;
            text-transform: capitalize;
          }

          .popup-menuitem-icon {
            margin-left: auto;
            position: relative;
            top: -1px;

            svg {
              width: 16px;
              height: 16px;
              color: rgb(144, 148, 165);
            }
          }
        }

        .active {
          border-left: 3px solid rgb(0, 152, 253);
          color: rgb(25, 32, 51);

          .popup-menuitem-icon {
            svg {
              color: rgb(93, 100, 123);
            }
          }
        }
      }

      .backup-to-dashboard {
        align-items: center;
        border: none;
        bottom: 2.5rem;
        background: none;
        color: rgb(144, 148, 165);
        display: inline-flex;
        justify-content: center;
        position: absolute;
        text-align: center;
        width: 100%;
        cursor: pointer;

        &:hover {
          color: rgb(25, 32, 51);
        }

        svg {
          width: 10px;
          height: 10px;
        }

        span {
          font-family: Inter, sans-serif;
          font-size: 0.8125rem;
          line-height: 1rem;
          letter-spacing: 0px;
          font-weight: 500;

          margin-left: 0.625rem;
        }
      }
    }

    .ep-popup-rigt-contain {
      position: relative;
      outline: none;
      margin: 0px auto;
      height: 800px;
      overflow: hidden;
      // background: rgb(255, 255, 255);
      min-width: 135px;
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .ep-popup-cotain {
      flex-direction: column;

      .ep-popup-left-contain {
        width: 100%;
        min-width: unset;

        h2 {
          padding: 2.5rem 1rem 1.5rem;
        }

        .backup-to-dashboard {
          display: none;
        }
      }
    }
  }

  @media (max-width: 300px) {
    .ep-popup-cotain {
      .ep-popup-left-contain {
        h2 {
          padding: 1.5rem 0.5rem 1rem;
          font-size: 1rem;
        }

        .popup-left-menuitems-wrapper {
          .popup-left-menuitem {
            padding: 0.5rem 1rem;
            margin: 2px 0px;

            .popup-menuitem-badge,
            .popup-menuitem-icon {
              display: none;
            }
          }
        }
      }
    }
  }
}