.custom-projects-wrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% - 1px);
  height: calc(100% - 8px);
  padding-top: 60px;

  .custom-projects-toolbar-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    z-index: 2;
    height: 64px;
    padding: 0px 32px 8px;
    flex-direction: column;

    .toolbar-Breadcrumbs-contain {
      flex-shrink: 1;
      min-width: 0px;
      display: inline-flex;
      flex-flow: row nowrap;
      width: 100%;
      align-items: center;

      a {
        text-decoration: none;
        flex: 0 0 auto;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 160px;
        color: inherit;
        padding: 0.25rem 8px;
        border-radius: 0.3125rem;
        font-family: Inter, sans-serif;
        font-size: 1.125rem;
        line-height: 1.5rem;
        letter-spacing: 0px;
        font-weight: 500;
        margin-left: -0.25rem;
        margin-right: 0.25rem;
        overflow: hidden;

        &:hover {
          background-color: rgb(238, 238, 240);
        }
      }
    }

    .toolbar-layoutOption-section {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      .projects-toolbar-style-contain {
        border-left: 1px solid rgb(239, 240, 242);
        padding: 0px 8px;
        flex-shrink: 0;
        cursor: pointer;

        &:hover {
          color: rgb(0, 152, 253);
        }

        svg {
          opacity: 0.6;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .projects-next-preview-btn {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    padding-top: 20px;
  }

  .custom-projects-contents-section {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    .projects-container {
      padding: 16px;

      .projects-item {
        width: 100%;

        .projectTitle-ThumbnailWrapper {
          width: 100%;
          aspect-ratio: 16 / 9;
          margin-bottom: 8px;
          border-radius: 5px;
          position: relative;
          display: grid;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          cursor: pointer;
          text-decoration: none;

          transition: box-shadow 0.2s ease-in-out 0s;

          &:hover {
            color: #0a58ca;
          }

          .projectTitle-ThumbnailBackgroundsContainer {
            position: absolute;
            inset: 0px;
            display: grid;
            align-items: center;
            justify-content: center;

            .projectTitle-ThumbnailImage-section {
              max-width: 100%;
              max-height: 100%;
              z-index: 3;
              grid-area: 1 / 1 / 1 / 1;
              overflow: hidden;
              justify-self: center;
            }

            .projectTitle-ThumbnailImageBackgroundOverlay {
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              background: rgba(124, 124, 124, 0.3);
              z-index: 2;
            }

            .projectTitle-ThumbnailImageBackground {
              width: 110%;
              position: absolute;
              left: -0.5rem;
              filter: blur(50px);
              z-index: 0;
            }
          }

          .projectTitle-ThumbnailActions-section {
            grid-area: 1 / 1 / 1 / 1;
            z-index: 4;
            justify-self: center;
            display: flex;
            gap: 2px;
            opacity: 0;
            transition: opacity 0.2s ease-in-out 0s;

            .ThumbnailAction__ThumbnailActionWrapper {
              border: 0px;
              margin: 0px;
              padding: 8px;
              border-radius: 7px;
              background: rgba(51, 51, 51, 0.6);
              color: rgb(255, 255, 255);
              font-family: Inter, sans-serif;
              font-size: 0.8125rem;
              letter-spacing: 0px;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: center;
              text-decoration: none;
              gap: 4px;
              line-height: 16px;

              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }

        .projectsTitle-TileInfoWrapper {
          display: grid;
          min-height: 40px;
          grid-template:
            "name buttons" min-content
            "time buttons" 1fr / 1fr min-content;

          .TileInfoWrapper-header {
            grid-area: name / name / name / name;
            font-family: Inter, sans-serif;
            font-size: 0.8125rem;
            line-height: 1rem;
            letter-spacing: 0px;
            font-weight: 500;
            // color: rgb(25, 32, 51);
            margin-bottom: 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .TileInfoWrapper-TileInfoTime {
            grid-area: time / time / time / time;
            font-family: Inter, sans-serif;
            font-size: 0.71875rem;
            line-height: 0.75rem;
            letter-spacing: 0.01em;
            font-weight: 400;
            color: rgb(160, 164, 178);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .TileInfoWrapper-TileInfoActions {
            grid-area: buttons / buttons / buttons / buttons;
            display: flex;

            .TileInfoActions-btn {
              background: transparent;
              appearance: none;
              outline: none;
              border: none;
              padding: 0px;
              user-select: none;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              transition: opacity 0.2s ease-in-out 0s;
              width: 40px;
              opacity: 1;
              cursor: pointer;

              &:hover {
                background: rgb(238, 238, 240);
              }

              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }

        &:hover {
          .projectTitle-ThumbnailWrapper {
            .projectTitle-ThumbnailActions-section {
              opacity: 1;
              pointer-events: auto;
              text-decoration: none;
            }
          }
        }
      }
    }

    .list-projects-container {
      display: flex;
      flex-direction: column;

      .projects-header-wrapper {
        display: none;

        .projects-header-NameTableCell1,
        .projects-header-NameTableCell2,
        .projects-header-NameTableCell3,
        .projects-header-NameTableCell4 {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          min-width: 0px;
        }

        .projects-header-NameTableCell1 {
          flex-grow: 1;
          flex-shrink: 2;
        }

        .projects-header-NameTableCell2 {
          flex: 0 0 100px;
        }

        .projects-header-NameTableCell3 {
          flex: 0 0 160px;
        }

        .projects-header-NameTableCell4 {
          flex: 0 0 48px;
        }
      }

      .project-list-item {
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        padding: 8px;
        position: relative;

        .project-listItem-cell1,
        .project-listItem-cell2,
        .project-listItem-cell3,
        .project-listItem-cell4 {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          min-width: 0px;

          img {
            width: 3rem;
            height: 3rem;
            border-radius: 10px;
            flex-shrink: 0;
            margin-right: 0.6875rem;
            background-size: cover;
          }

          .listItem-cell1-text {
            .listItem-cell1-header {
              font-family: Inter, sans-serif;
              font-size: 0.8125rem;
              line-height: 1rem;
              letter-spacing: 0px;
              font-weight: 500;
              color: rgb(25, 32, 51);
              width: 100%;
              overflow: hidden;
              white-space: pre-wrap;
              overflow-wrap: anywhere;
              text-overflow: ellipsis;
            }
          }

          .listItem-cell2-text {
            font-family: Inter, sans-serif;
            font-size: 0.8125rem;
            line-height: 1rem;
            letter-spacing: 0px;
            font-weight: 500;
            color: rgb(93, 100, 123);
            white-space: pre-wrap;
            overflow-wrap: anywhere;
            overflow: hidden;
            text-overflow: ellipsis;

            &::before {
              content: "";
              display: inline-block;
              width: 8px;
              margin-right: 0.5rem;
              height: 8px;
              border-radius: 10rem;
              background-color: rgb(255, 116, 52);
              opacity: 0.8;
            }
          }

          .listItem-cell3-text {
            font-family: Inter, sans-serif;
            font-size: 0.8125rem;
            line-height: 1rem;
            letter-spacing: 0px;
            font-weight: 500;
            color: rgb(93, 100, 123);
            overflow: hidden;
            white-space: pre-wrap;
            overflow-wrap: anywhere;
            text-overflow: ellipsis;
          }

          svg {
            color: rgb(144, 148, 165);
            height: auto;
            width: 16px;
            transform: unset;
          }
        }

        .project-listItem-cell1 {
          flex-grow: 1;
          flex-shrink: 2;
          text-decoration: none;
        }

        .project-listItem-cell2 {
          flex: 0 0 100px;
        }

        .project-listItem-cell3 {
          flex: 0 0 160px;
        }

        .project-listItem-cell4 {
          flex: 0 0 48px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    padding-top: 50px;

    .custom-projects-toolbar-section {
      // background: rgb(247, 247, 248);
      border-bottom: 1px solid rgb(238, 238, 240);
      z-index: 10;
      flex-direction: row;
    }

    .custom-projects-contents-section {
      .list-projects-container {
        .projects-header-wrapper {
          font-family: Inter, sans-serif;
          font-size: 0.8125rem;
          line-height: 1rem;
          letter-spacing: 0px;
          font-weight: 500;
          color: rgb(25, 32, 51);
          padding: 8px 24px 8px 16px;
          border-bottom: 1px solid rgb(238, 238, 240);
          display: flex;

          .projects-header-NameTableCell1,
          .projects-header-NameTableCell2,
          .projects-header-NameTableCell3 {
            margin-right: 1rem;
          }
        }

        .project-list-item {
          padding: 8px 24px 8px 16px;
          border-bottom: 1.5px solid rgb(238, 238, 240);

          .project-listItem-cell1,
          .project-listItem-cell2,
          .project-listItem-cell3,
          .project-listItem-cell4 {
            margin-right: 1rem;

            .listItem-cell1-text {
              .listItem-cell1-body {
                display: none;
              }
            }

            svg {
              width: 24px;
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .custom-projects-contents-section {
      .projects-container {
        padding: 1.875rem 32px 32px;
      }
    }
  }

  @media (max-width: 767px) {
    .custom-projects-contents-section {
      .list-projects-container {
        .project-list-item {

          .project-listItem-cell1,
          .project-listItem-cell4 {
            .listItem-cell1-text {
              .listItem-cell1-body-contain {
                grid-area: bottom-left / bottom-left / bottom-left / bottom-left;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                min-width: 0px;
                margin-top: 3px;

                .listItem-cell1-body {
                  display: flex;
                  flex: 1 1 auto;
                  font-family: Inter, sans-serif;
                  font-size: 0.8125rem;
                  line-height: 1rem;
                  letter-spacing: 0px;
                  font-weight: 500;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  color: rgb(93, 100, 123);
                  align-items: center;
                }
              }
            }
          }


          .project-listItem-cell2,
          .project-listItem-cell3 {
            display: none;
          }
        }
      }
    }
  }
}