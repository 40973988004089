body {
	// background-color: #f7f7f8;
	margin: 0;
	min-height: 100%;
	font-family: 'Euclidcirculara webfont', Arial, sans-serif;
	color: #192033;
	font-size: 14px;
	line-height: 20px;

	&::-webkit-scrollbar {
		width: 1px;
	}
}

.T_containner,
.pricing_containner {
	.Header {}

	.content-containner {
		.section-15 {
			padding-right: 3%;
			padding-left: 3%;
		}

		.section-9 {
			margin-bottom: 128px;
			padding-right: 3%;
			padding-left: 3%;
		}

		.section-9.directory {
			margin-bottom: 48px;
		}

		.div-1232 {
			overflow: hidden;
			max-width: 1232px;
			margin-right: auto;
			margin-left: auto;
			padding-right: 16px;
			padding-left: 16px;
			padding: 16px;
			width: 100%;

			@media screen and (max-width: 991px) {
				padding-right: 10px;
				padding-left: 10px;
			}
		}

		.div-1232.wide._3 {
			max-width: 1080px;
		}

		.div-1232.wide {
			max-width: 1343px;
		}

		.div-1232._1300 {
			max-width: 1300px;
			padding-right: 0;
			padding-left: 0;
		}

		.div-block-67 {
			padding: 64px 64px 48px;
			border-radius: 30px;
			box-shadow: 0px 0px 5px 2px;
				// background-color: #e3e3ed;
		}

		.div-block-70 {
			padding-top: 96px;
			padding-bottom: 64px;
		}

		.div-block-69 {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		.div-block-68 {
			display: grid;
			padding-top: 64px;
			padding-bottom: 0;
			grid-auto-columns: 1fr;
			grid-column-gap: 48px;
			grid-row-gap: 16px;
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-rows: auto;
		}

		.div-block-72 {
			display: flex;
			width: 100%;
			height: 388px;
			max-width: 772px;
			margin: 32px auto;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-radius: 24px;
			// background-image: url('../../image/online-video/background.jpg');
			background-size: cover;
		}

		.div-block-79 {
			max-width: 1232px;
			margin-right: auto;
			margin-left: auto;
			padding-top: 48px;
			padding-bottom: 48px;
			border: 1px solid #dfe0e5;
			border-radius: 30px;
		}

		.div-block-103 {
			max-width: 1120px;
			margin-right: auto;
			margin-left: auto;
			padding-top: 120px;
			padding-bottom: 120px;
		}

		@media screen and (max-width: 479px) {
			.div-block-67 {
				padding: 24px;
			}
		}

		.heading-19 {
			margin-bottom: 24px;
			font-size: 24px;
			font-weight: 600;
		}

		.heading-20 {
			font-size: 34px;
			font-weight: 400;
		}

		.heading-21 {
			margin-top: 32px;
			margin-bottom: 0;
			font-weight: 600;
		}

		.heading-24 {
			font-size: 32px;
			line-height: 1.4em;
			font-weight: 400;
		}

		.heading-21.m-y-0 {
			margin-top: 0;
			font-size: 20px;
			font-weight: 600;
			text-align: center;
		}

		.heading-26 {
			font-size: 28px;
			font-weight: 400;
			margin-top: 0;
			margin-bottom: 16px;
			line-height: 1.3em;
		}

		.paragraph-5 {
			text-align: center;
		}

		.w-inline-block {
			max-width: 100%;
			display: inline-block;
		}

		.content-section {
			padding: 64px 3%;
		}

		.container-11 {
			width: 100%;
			max-width: 1140px;
			margin-right: auto;
			margin-left: auto;
		}

		.text-block-36 {
			color: #0098fd;
			font-size: 64px;
		}

		.section-4 {
			margin-top: 220px;
			margin-bottom: 220px;
		}

		.section-4.other {
			display: block;
			margin-top: 128px;
			margin-bottom: 128px;
			padding-top: 0;
			background-image: none;
		}

		.section-4.other.main {
			display: block;
		}

		.section-21 {
			margin-top: 0;
		}

		.w-container {
			margin-left: auto;
			margin-right: auto;
			max-width: 940px;

			@media screen and (max-width: 991px) {
				max-width: 728px;
			}

			@media screen and (max-width: 479px) {
				max-width: none;
			}
		}

		.bold-text-3 {
			font-family: 'Euclidcirculara webfont', Arial, sans-serif;
			font-weight: 700;
		}

		.container-9 {
			max-width: 1140px;
		}

		.container1140 {
			max-width: 1140px;
			margin-right: auto;
			margin-left: auto;
		}

		.testimonial-grid {
			display: grid;
			padding-top: 64px;
			padding-bottom: 0;
			grid-auto-columns: 1fr;
			grid-column-gap: 16px;
			grid-row-gap: 16px;
			grid-template-columns: 0.5fr 1fr;
			grid-template-rows: auto;
		}

		.h3-blog {
			margin-top: 0;
			margin-bottom: 0;
			color: #192033;
			font-size: 22px;
			line-height: 1.4em;
			font-weight: 500;
		}

		.section-10.m-y-b-64 {
			margin-bottom: 64px;
		}

		.section-10 {
			margin-top: 122px;
		}

		.div-block-77 {
			position: relative;
			z-index: 10;
			display: flex;
			margin-bottom: 64px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}

	.footer-containner {}
}

.content-containner {
	.h-section {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
		grid-auto-columns: 1fr;
		grid-column-gap: 16px;
		grid-row-gap: 16px;
	}

	.top-section {
		position: static;
		top: 0;
		margin-top: 100px;
		margin-bottom: 64px;

		.h-section-container {
			margin-left: auto;
			margin-right: auto;
			max-width: 940px;

			.hs1-Header {
				position: static;
				top: 0;
				margin-bottom: 0;
				font-family: Palatino;
				// color: #192033;
				font-size: 46px;
				line-height: 46px;
				text-align: center;
				font-weight: 700;
				margin: 0.67em 0 0;

				.text-span-3 {
					font-weight: 200;
					font-size: 45px;
				}
			}

			.hs1-content {
				margin-top: 32px;
				margin-bottom: 16px;
				// color: #192033;
				font-size: 24px;
				font-weight: 400;
				font-family: 'Euclidcirculara webfont', Arial, sans-serif;
				max-width: 100%;
				line-height: 1.4em;
				overflow: visible;
			}

			.hs1-content2 {
				margin-bottom: 48px;
				// color: #363e52cf;
				font-family: 'Euclidcirculara webfont', Arial, sans-serif;
				max-width: 100%;
				margin-top: 10px;
				font-size: 18px;
				line-height: 1.4em;
				font-weight: 100;
				display: flex;
				justify-content: center;
			}

			.main-hero-cta {
				position: relative;
				display: flex;
				width: auto;
				align-items: center;
				justify-content: center;

				a {
					text-decoration: none;
				}

				.uploadbtn {
					padding: 24px 48px;
					background-color: #192033;
					border: 12px #000;
					border-radius: 1000px;
					transition: .3s;
					text-decoration: none;
					color: #fff;
					font-size: 20px;
					font-weight: 400;
					cursor: pointer;
					text-align: center;
				}
				
			}
			
			.main-here-footer {
				margin-top: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
				user-select: none;

				.trysamplebtn {
					display: flex;
					flex-direction: row;
					align-items: center;

					font-family: 'Euclidcirculara webfont', Arial, sans-serif;
					// color: #192033;
					font-size: 14px;
					font-weight: 600;
					cursor: pointer;
				}
			}
		}
	}
}

.swiper {
	width: 100%;
	padding-top: 50px;
	padding-bottom: 50px;
  }
  
  .swiper-slide {
	background-position: center;
	background-size: cover;
	width: 70% !important;
	

	div {
		display: block;
		width: 100%;
		
	  }
  }

.video-container {
	position: relative;
	width: 100%; /* Adjust based on your layout */
}

.video-container video {
	width: 100%; /* This will make the video fill the container */
	display: block;
}

.play-button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* Style your button as needed */
	padding: 10px 20px;
	font-size: 16px;
	cursor: pointer;
}

.play-button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1; /* Ensure the button is above the video */
	border: none;
	background: none; /* Remove default background */
	cursor: pointer;
	padding: 0; /* Remove padding */
  }

